import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class History extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__our-history-area pt-100 pb-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<img src={publicUrl + "assets/img/banner/opportunites-neoimo.png"} alt="#" className='pb-60' />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__our-history-inner">
							<div className="tab-content">
								<div className="tab-pane fade active show" id="liton_tab_2_2">
									<div className="ltn__product-tab-content-inner">
										<div className="row">
											<div className="col-lg-12 align-self-center">
												<div className="about-us-info-wrap">
													<div className="section-title-area ltn__section-title-2--- text-center---">
														<h1 className="section-title">Un projet de vente en cours ou à venir ?
															Rencontrons nous afin d’échanger à ce sujet.</h1>
													</div>
													<span style={{ fontSize: "22px" }}>Nous comprenons à quel point il est important d’obtenir une estimation précise de la valeur de votre bien. C’est pourquoi notre équipe forts de leur expérience locale, est là pour vous fournir une estimation réaliste qui reflète véritablement le marché actuel.</span> <br></br>
													<span style={{ fontSize: "22px" }}>Vous souhaitez bénéficier d’une estimation ? Contactez-nous par téléphone au : <a href='tel:0329941800'>03.29.94.18.00</a></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	}
}

export default History