import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import AboutNeoimo from './section-components/about-neoimo';
import AboutHistoire from './section-components/about-histoire';
import TeamV1 from './section-components/team-v1';

const AboutV2 = () => {

    return <div>
        <TopBar />
        <Navbar />
        <AboutNeoimo/>
        <AboutHistoire/>
        <TeamV1 />
        <Footer />
    </div>
}

export default AboutV2

