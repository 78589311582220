import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-grid-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import ShopGridSearch from './shop-components/shop-grid-search';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const ShopSearch = () => {
    const location = useLocation();

    console.log("location", location)

    return <div>
        <TopBar />
        <Navbar />
        {/* <PageHeader headertitle="Nos biens disponibles à la recherche :" /> */}
        <ShopGridSearch />
        <Footer />
    </div>
}

export default ShopSearch

