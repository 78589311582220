import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';

class TopBar extends Component {

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<div>
				<div className="ltn__header-top-area section-bg-6">
					<div className="container">
						<div className="row">
							<div className="col-md-7">
								<div className="ltn__top-bar-menu">
									<ul>
										<li><a href="mailto:info@neoimo.com?Subject=Flower%20greetings%20to%20you"><i className="icon-phone" />03.29.94.18.00</a></li>
										<li><a href="mailto:info@neoimo.com?Subject=Flower%20greetings%20to%20you"><i className="icon-mail" /> info@neoimo.com</a></li>
										<li><a href="locations.html"><i className="icon-placeholder" />48 rue Saint-Jean, 88300 Neufchâteau</a></li>
									</ul>
								</div>
							</div>
							<div className="col-md-5">
								<div className="top-bar-right text-end">
									<div className="ltn__top-bar-menu">
										<ul>
											<li>
												{/* ltn__social-media */}
												<div className="ltn__social-media">
													<ul>
														<li style={{ cursor: "pointer" }}><a href="https://www.facebook.com/NEOIMONEUFCHATEAU/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" style={{ background: "white", borderRadius: 99, padding: "4px 8px", color: "#AC2E31", cursor: "pointer", marginRight: "5px" }} />Suivez-nous sur Facebook !</a></li>
													</ul>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


export default TopBar