import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from 'axios';

const BlogSlider = () => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	const [biens, setBiens] = useState([]);
	const [sortedBiens, setSortedBiens] = useState([]);
	const [filterBiens, setFilterBiens] = useState([]);
	const [triSelectionne, setTriSelectionne] = useState('Pertinence');

	const parseData = (data) => {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(data, 'text/xml');
		const biensXML = xmlDoc.getElementsByTagName('BIEN');
		const currentTime = new Date().toUTCString();
		localStorage.setItem('lastFetchedTime', currentTime);

		const biensParsed = Array.from(biensXML).map(bien => {

			const infoGenerales = bien.getElementsByTagName('INFO_GENERALES')[0];
			const agence = bien.getElementsByTagName('AGENCE')[0];

			const dateCreation = infoGenerales.getElementsByTagName('DATE_CREATION')[0]?.textContent || 'Date inconnue';
			const reference = infoGenerales.getElementsByTagName('AFF_NUM')[0]?.textContent || 'Référence inconnue';

			const intituleElement = bien.getElementsByTagName('INTITULE')[0];
			const commentaireElement = bien.getElementsByTagName('COMMENTAIRES')[0];
			const intituleFR = intituleElement ? intituleElement.getElementsByTagName('FR')[0].textContent : '';
			const commentaireFR = commentaireElement ? commentaireElement.getElementsByTagName('FR')[0].textContent : '';

			const localisation = bien.getElementsByTagName('LOCALISATION')[0];
			const codePostal = localisation.getElementsByTagName('CODE_POSTAL')[0]?.textContent || 'Non spécifié';
			const ville = localisation.getElementsByTagName('VILLE')[0]?.textContent || 'Non spécifié';

			let prixLoyer;
			let typeBien;
			let type;
			let numMandat;
			let typeMandat;


			if (bien.getElementsByTagName('VENTE').length > 0) {
				const vente = bien.getElementsByTagName('VENTE')[0];
				prixLoyer = vente.getElementsByTagName('PRIX')[0]?.textContent || 'Non spécifié';
				numMandat = vente.getElementsByTagName('NUM_MANDAT')[0]?.textContent || 'Non spécifié';
				typeMandat = vente.getElementsByTagName('TYPE_MANDAT')[0]?.textContent || 'Non spécifié';
				typeBien = 'PRIX';
			} else if (bien.getElementsByTagName('LOCATION').length > 0) {
				const location = bien.getElementsByTagName('LOCATION')[0];
				prixLoyer = location.getElementsByTagName('LOYER')[0]?.textContent || 'Non spécifié';
				numMandat = location.getElementsByTagName('NUM_MANDAT')[0]?.textContent || 'Non spécifié';
				typeMandat = location.getElementsByTagName('TYPE_MANDAT')[0]?.textContent || 'Non spécifié';
				typeBien = 'LOYER';
			} else {
				prixLoyer = 'Information indisponible';
				typeBien = 'Indéfini';
			}

			let nbrePieces = null;
			let nbreChambres = null;
			let surfaceHabitable = null;
			let surfaceTerrain = null;
			let premiereImage = null;
			let images = null;
			let modeChauffage = null;
			let contrat = null;
			let nbreSallesDeBain = null;
			let consommationEnergie = null;
			let gazEffetSerre = null;
			let consoAnnuelleEnergie = null;
			let valeurGes = null;
			let chargeEnergieMin = null;
			let chargeEnergieMax = null;

			const typesBiens = [
				'AGRICOLE_VITICOLE',
				'APPARTEMENT',
				'CAVE',
				'DEMEURE',
				'FOND_COMMERCE',
				'FORET',
				'GRANGE',
				'IMMEUBLE',
				'LOCAL_PROFESSIONNEL',
				'MAISON',
				'MARINA',
				'PARKING',
				'PROGRAMME_NEUF',
				'TERRAIN'
			];

			for (const typeBien of typesBiens) {
				if (bien.getElementsByTagName(typeBien).length > 0) {
					const typeElement = bien.getElementsByTagName(typeBien)[0];
					const piecesElement = typeElement.getElementsByTagName('NBRE_PIECES')[0];
					const chambresElement = typeElement.getElementsByTagName('NBRE_CHAMBRES')[0];
					const sallesDeBainElement = typeElement.getElementsByTagName('NBRE_SALLE_BAIN')[0];
					const surfaceElement = typeElement.getElementsByTagName('SURFACE_HABITABLE')[0];
					const surfaceTerrainElement = typeElement.getElementsByTagName('SURFACE_TERRAIN')[0];
					const consommationEnergieElement = typeElement.getElementsByTagName('CONSOMMATIONENERGETIQUE')[0];
					const gazEffetSerreElement = typeElement.getElementsByTagName('GAZEFFETDESERRE')[0];
					const consoAnnuelleElement = typeElement.getElementsByTagName('CONSO_ANNUEL_ENERGIE')[0];
					const chargeEnergieMinElement = typeElement.getElementsByTagName('CHARGE_ENERGIE_MIN')[0];
					const chargeEnergieMaxElement = typeElement.getElementsByTagName('CHARGE_ENERGIE_MAX')[0];
					const valeurGesElement = typeElement.getElementsByTagName('VALEUR_GES')[0];
					const chauffage = typeElement.getElementsByTagName('CHAUFFAGE')[0];
					const imagesElement = bien.getElementsByTagName('IMAGES')[0];

					type = typeBien;

					if (piecesElement) {
						nbrePieces = piecesElement.textContent;
					}
					if (chambresElement) {
						nbreChambres = chambresElement.textContent;
					}
					if (surfaceElement) {
						surfaceHabitable = surfaceElement.textContent;
					}
					if (surfaceTerrainElement) {
						surfaceTerrain = surfaceTerrainElement.textContent;
					}
					if (chauffage && chauffage.textContent) {
						modeChauffage = chauffage.textContent;
					} else {
						modeChauffage = null;
					}
					if (sallesDeBainElement && sallesDeBainElement.textContent) {
						nbreSallesDeBain = sallesDeBainElement.textContent;
					} else {
						nbreSallesDeBain = null;
					}
					if (consommationEnergieElement && consommationEnergieElement.textContent) {
						consommationEnergie = consommationEnergieElement.textContent;
					} else {
						consommationEnergie = null;
					}
					if (gazEffetSerreElement && gazEffetSerreElement.textContent) {
						gazEffetSerre = gazEffetSerreElement.textContent;
					} else {
						gazEffetSerre = null;
					}
					if (consoAnnuelleElement && consoAnnuelleElement.textContent) {
						consoAnnuelleEnergie = consoAnnuelleElement.textContent;
					} else {
						consoAnnuelleEnergie = null;
					}
					if (valeurGesElement && valeurGesElement.textContent) {
						valeurGes = valeurGesElement.textContent;
					} else {
						valeurGes = null;
					}
					if (chargeEnergieMinElement && chargeEnergieMinElement.textContent) {
						chargeEnergieMin = chargeEnergieMinElement.textContent;
					} else {
						chargeEnergieMin = null;
					}
					if (chargeEnergieMaxElement && chargeEnergieMaxElement.textContent) {
						chargeEnergieMax = chargeEnergieMaxElement.textContent;
					} else {
						chargeEnergieMax = null;
					}
					if (imagesElement) {
						const imageElements = imagesElement.getElementsByTagName('IMG');
						const imagesUrls = []; // Initialiser une liste pour les URLs des images

						for (let i = 0; i < imageElements.length; i++) {
							const imageElement = imageElements[i];
							const imageUrl = imageElement.textContent; // Obtenir l'URL de l'image
							imagesUrls.push(imageUrl); // Ajouter l'URL à la liste
							images = imagesUrls;
						}

						if (imageElements.length > 0) {
							// Obtenez l'URL de la première image
							const firstImageElement = imageElements[0];
							premiereImage = firstImageElement.textContent; // ou firstImageElement.textContent, en fonction de la structure
						}
					}
				}
			}

			nbrePieces = nbrePieces && parseInt(nbrePieces) ? nbrePieces : null;
			nbreChambres = nbreChambres && parseInt(nbreChambres) ? nbreChambres : null;
			nbreSallesDeBain = nbreSallesDeBain ? nbreSallesDeBain : null;
			surfaceHabitable = surfaceHabitable && parseInt(surfaceHabitable) ? surfaceHabitable : null;
			surfaceTerrain = surfaceTerrain && parseInt(surfaceTerrain) ? surfaceTerrain : null;
			modeChauffage = modeChauffage ? modeChauffage : null;
			consommationEnergie = consommationEnergie ? consommationEnergie : null;
			gazEffetSerre = gazEffetSerre ? gazEffetSerre : null;
			consoAnnuelleEnergie = consoAnnuelleEnergie ? consoAnnuelleEnergie : null;
			valeurGes = valeurGes ? valeurGes : null;
			chargeEnergieMin = chargeEnergieMin ? chargeEnergieMin : null;
			chargeEnergieMax = chargeEnergieMax ? chargeEnergieMax : null;


			if (prixLoyer) {
				prixLoyer = parseInt(prixLoyer).toLocaleString('fr-FR');
			}

			contrat = bien.getElementsByTagName('VENTE').length > 0 ? 'VENTE' : (bien.getElementsByTagName('LOCATION').length > 0 ? 'LOCATION' : 'INCONNU');

			return {
				affId: infoGenerales.getElementsByTagName('AFF_ID')[0].textContent,
				agenceNom: agence.getElementsByTagName('AGENCE_NOM')[0].textContent,
				intitule: intituleFR,
				commentaire: commentaireFR,
				prixLoyer: prixLoyer,
				typeBien: type,
				codePostal: codePostal,
				ville: ville,
				nbrePieces: nbrePieces,
				nbreChambres: nbreChambres,
				surfaceHabitable: surfaceHabitable,
				surfaceTerrain: surfaceTerrain,
				premiereImage: premiereImage,
				images: images,
				dateCreation: dateCreation,
				reference: reference,
				type: contrat,
				modeChauffage: modeChauffage,
				nbreSallesDeBain: nbreSallesDeBain,
				numMandat: numMandat,
				typeMandat: typeMandat,
				consommationEnergie: consommationEnergie,
				gazEffetSerre: gazEffetSerre,
				consoAnnuelleEnergie: consoAnnuelleEnergie,
				valeurGes: valeurGes,
				chargeEnergieMin: chargeEnergieMin,
				chargeEnergieMax: chargeEnergieMax,
				// ...autres champs
			};
		}).filter(bien => bien !== null);

		return biensParsed;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('https://neoimo.com/script.php');
				const currentTime = new Date().toUTCString();
				localStorage.setItem('lastFetchedTime', currentTime);

				const allBiens = parseData(response.data);
				setBiens(allBiens);

				const randomBiens = allBiens.sort(() => Math.random() - Math.random()).slice(0, 10);
				setFilterBiens(randomBiens)


				localStorage.setItem('biensData', JSON.stringify(allBiens));

				// Les données ont été mises à jour, mettez à jour l'état biens
				// const biensParsed = parseData(response.data);
				// setBiens(biensParsed);
				// localStorage.setItem('biensData', JSON.stringify(biensParsed));

				// Ajoutez un log pour indiquer que les données ont été mises à jour
				console.log('Données mises à jour avec succès.');
			} catch (error) {
				console.error('Erreur lors de la récupération des données depuis le backend:', error);
			}
		};

		fetchData();
	}, []);

	const trierBiens = (tri) => {
		let biensTries;
		switch (tri) {
			case 'plus recent':
				// Supposons que 'date' est la propriété de date de bien
				biensTries = [...biens].sort((a, b) => new Date(b.dateCreation) - new Date(a.dateCreation));
				break;
			case 'plus anciens':
				biensTries = [...biens].sort((a, b) => new Date(a.dateCreation) - new Date(b.dateCreation));
				break;
			case 'prix croissant':
				biensTries = [...biens].sort((a, b) => parseFloat(a.prixLoyer) - parseFloat(b.prixLoyer));
				break;
			case 'prix décroissant':
				biensTries = [...biens].sort((a, b) => parseFloat(b.prixLoyer) - parseFloat(a.prixLoyer));
				break;
			default:
				biensTries = [...biens];
		}
		setSortedBiens(biensTries);
	};

	useEffect(() => {
		if (biens.length > 0) {
			trierBiens(triSelectionne);
		}
	}, [triSelectionne, biens]);

	// useEffect(() => {
	// 	if (!document.getElementById('facebook-jssdk')) {
	// 		const script = document.createElement('script');
	// 		script.id = 'facebook-jssdk';
	// 		script.src = "//connect.facebook.net/en_US/all.js#xfbml=1";
	// 		document.body.appendChild(script);
	// 	}
	// }, [])

	return (
		<div className="ltn__feature-area go-top pb-90">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<img src={publicUrl + "assets/img/banner/opportunites-neoimo.png"} alt="#" className='mt-30' />
						</div>
					</div>
				</div>
				<div className="row ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
					{filterBiens.map((bien, index) => (
						<div className="col-lg-4" key={index}>
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img" style={{ margin: 0 }}>
									<Link to={`/biens/${bien.affId}`}>
										<img src={bien.premiereImage} alt={bien.intitule} style={{ height: "280px", width: "100%", objectFit: "cover" }} />
									</Link>
								</div>
								<div className="ltn__blog-brief" style={{ padding: "20px 30px 20px 30px" }}>
									<div className="ltn__blog-meta">
										<h4 style={{ height: "60px", fontWeight: 500 }}>{bien.intitule}</h4>
									</div>
									<div className="ltn__blog-meta">
										{bien.typeBien === 'LOYER' ? (
											<h4>LOYER: {bien.prixLoyer} €</h4>
										) : (
											<h4>PRIX: {bien.prixLoyer} €</h4>
										)}
									</div>
									<div className="ltn__blog-meta">
										<span><i className="fas fa-map-marker-alt"></i> {bien.codePostal} - {bien.ville}</span>
									</div>
									<div className="ltn__blog-meta">
										<ul>
											{bien.nbrePieces && (
												<li className="ltn__blog-author">
													<i className="fa fa-layer-group"></i> {bien.nbrePieces}
												</li>
											)}
											{bien.nbreChambres && (
												<li className="ltn__blog-tags">
													<i className="fa fa-bed"></i> {bien.nbreChambres}
												</li>
											)}
											{bien.surfaceHabitable && (
												<li className="ltn__blog-tags">
													<i className='fas fa-expand'></i> {bien.surfaceHabitable} m²
												</li>
											)}
										</ul>
									</div>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-btn">
											<Link to={`/biens/${bien.affId}`}>Voir le détail du bien</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						// <div className="col-xl-4 col-sm-6 col-12 go-top">
						//     <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
						//         <div className="product-img">
						//             <Link to="/shop"><img src={publicUrl + "assets/img/product-3/1.jpg"} alt="#" /></Link>
						//             <div className="real-estate-agent">
						//                 <div className="agent-img">
						//                     <Link to="/team-details"><img src={publicUrl + "assets/img/blog/author.jpg"} alt="#" /></Link>
						//                 </div>
						//             </div>
						//         </div>
						//         <div className="product-info">
						//             <div className="product-badge">
						//                 <ul>
						//                     <li className="sale-badg">For Rent</li>
						//                 </ul>
						//             </div>
						//             <h2 className="product-title"><Link to="/shop">New Apartment Nice View</Link></h2>
						//             <div className="product-img-location">
						//                 <ul>
						//                     <li>
						//                         <Link to="/shop"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
						//                     </li>
						//                 </ul>
						//             </div>
						//             <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
						//                 <li><span>3 </span>
						//                     Bedrooms
						//                 </li>
						//                 <li><span>2 </span>
						//                     Bathrooms
						//                 </li>
						//                 <li><span>3450 </span>
						//                     square Ft
						//                 </li>
						//             </ul>
						//             <div className="product-hover-action">
						//                 <ul>
						//                     <li>
						//                         <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
						//                             <i className="flaticon-expand" />
						//                         </a>
						//                     </li>
						//                     <li>
						//                         <a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
						//                             <i className="flaticon-heart-1" /></a>
						//                     </li>
						//                     <li>
						//                         <Link to="/shop" title="Compare">
						//                             <i className="flaticon-add" />
						//                         </Link>
						//                     </li>
						//                 </ul>
						//             </div>
						//         </div>
						//         <div className="product-info-bottom">
						//             <div className="product-price">
						//                 <span>$349,00<label>/Month</label></span>
						//             </div>
						//         </div>
						//     </div>
						// </div>
					))}
				</div>
			</div>
		</div>
	)
}

export default BlogSlider