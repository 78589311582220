import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';

const ContactV1 = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant' // ou 'instant', mais 'auto' est généralement le comportement par défaut
        });
    }, []);

    return <div>
        <TopBar />
        <Navbar />
        <Map />
        <ContactInfo />
        <ContactForm />
        <Footer />
    </div>
}

export default ContactV1

