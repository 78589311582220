import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from 'axios';

const ShopGridV1 = (bien) => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	const [biens, setBiens] = useState([]);
	const [sortedBiens, setSortedBiens] = useState([]);
	const [triSelectionne, setTriSelectionne] = useState('Pertinence');

	const parseData = (data) => {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(data, 'text/xml');
		const biensXML = xmlDoc.getElementsByTagName('BIEN');
		const currentTime = new Date().toUTCString();
		localStorage.setItem('lastFetchedTime', currentTime);

		const biensParsed = Array.from(biensXML).map(bien => {

			const infoGenerales = bien.getElementsByTagName('INFO_GENERALES')[0];
			const agence = bien.getElementsByTagName('AGENCE')[0];

			const dateCreation = infoGenerales.getElementsByTagName('DATE_CREATION')[0]?.textContent || 'Date inconnue';
			const reference = infoGenerales.getElementsByTagName('AFF_NUM')[0]?.textContent || 'Référence inconnue';

			const intituleElement = bien.getElementsByTagName('INTITULE')[0];
			const commentaireElement = bien.getElementsByTagName('COMMENTAIRES')[0];
			const intituleFR = intituleElement ? intituleElement.getElementsByTagName('FR')[0].textContent : '';
			const commentaireFR = commentaireElement ? commentaireElement.getElementsByTagName('FR')[0].textContent : '';

			const localisation = bien.getElementsByTagName('LOCALISATION')[0];
			const codePostal = localisation.getElementsByTagName('CODE_POSTAL')[0]?.textContent || 'Non spécifié';
			const ville = localisation.getElementsByTagName('VILLE')[0]?.textContent || 'Non spécifié';

			let prixLoyer;
			let typeBien;
			let type;
			let numMandat;
			let typeMandat;


			if (bien.getElementsByTagName('VENTE').length > 0) {
				const vente = bien.getElementsByTagName('VENTE')[0];
				prixLoyer = vente.getElementsByTagName('PRIX')[0]?.textContent || 'Non spécifié';
				numMandat = vente.getElementsByTagName('NUM_MANDAT')[0]?.textContent || 'Non spécifié';
				typeMandat = vente.getElementsByTagName('TYPE_MANDAT')[0]?.textContent || 'Non spécifié';
				typeBien = 'PRIX';
			} else if (bien.getElementsByTagName('LOCATION').length > 0) {
				const location = bien.getElementsByTagName('LOCATION')[0];
				prixLoyer = location.getElementsByTagName('LOYER')[0]?.textContent || 'Non spécifié';
				numMandat = location.getElementsByTagName('NUM_MANDAT')[0]?.textContent || 'Non spécifié';
				typeMandat = location.getElementsByTagName('TYPE_MANDAT')[0]?.textContent || 'Non spécifié';
				typeBien = 'LOYER';
			} else {
				prixLoyer = 'Information indisponible';
				typeBien = 'Indéfini';
			}

			let nbrePieces = null;
			let nbreChambres = null;
			let surfaceHabitable = null;
			let surfaceTerrain = null;
			let premiereImage = null;
			let images = null;
			let modeChauffage = null;
			let contrat = null;
			let nbreSallesDeBain = null;
			let consommationEnergie = null;
			let gazEffetSerre = null;
			let consoAnnuelleEnergie = null;
			let valeurGes = null;
			let chargeEnergieMin = null;
			let chargeEnergieMax = null;

			const typesBiens = [
				'AGRICOLE_VITICOLE',
				'APPARTEMENT',
				'CAVE',
				'DEMEURE',
				'FOND_COMMERCE',
				'FORET',
				'GRANGE',
				'IMMEUBLE',
				'LOCAL_PROFESSIONNEL',
				'MAISON',
				'MARINA',
				'PARKING',
				'PROGRAMME_NEUF',
				'TERRAIN'
			];

			for (const typeBien of typesBiens) {
				if (bien.getElementsByTagName(typeBien).length > 0) {
					const typeElement = bien.getElementsByTagName(typeBien)[0];
					const piecesElement = typeElement.getElementsByTagName('NBRE_PIECES')[0];
					const chambresElement = typeElement.getElementsByTagName('NBRE_CHAMBRES')[0];
					const sallesDeBainElement = typeElement.getElementsByTagName('NBRE_SALLE_BAIN')[0];
					const surfaceElement = typeElement.getElementsByTagName('SURFACE_HABITABLE')[0];
					const surfaceTerrainElement = typeElement.getElementsByTagName('SURFACE_TERRAIN')[0];
					const consommationEnergieElement = typeElement.getElementsByTagName('CONSOMMATIONENERGETIQUE')[0];
					const gazEffetSerreElement = typeElement.getElementsByTagName('GAZEFFETDESERRE')[0];
					const consoAnnuelleElement = typeElement.getElementsByTagName('CONSO_ANNUEL_ENERGIE')[0];
					const chargeEnergieMinElement = typeElement.getElementsByTagName('CHARGE_ENERGIE_MIN')[0];
					const chargeEnergieMaxElement = typeElement.getElementsByTagName('CHARGE_ENERGIE_MAX')[0];
					const valeurGesElement = typeElement.getElementsByTagName('VALEUR_GES')[0];
					const chauffage = typeElement.getElementsByTagName('CHAUFFAGE')[0];
					const imagesElement = bien.getElementsByTagName('IMAGES')[0];

					type = typeBien;

					if (piecesElement) {
						nbrePieces = piecesElement.textContent;
					}
					if (chambresElement) {
						nbreChambres = chambresElement.textContent;
					}
					if (surfaceElement) {
						surfaceHabitable = surfaceElement.textContent;
					}
					if (surfaceTerrainElement) {
						surfaceTerrain = surfaceTerrainElement.textContent;
					}
					if (chauffage && chauffage.textContent) {
						modeChauffage = chauffage.textContent;
					} else {
						modeChauffage = null;
					}
					if (sallesDeBainElement && sallesDeBainElement.textContent) {
						nbreSallesDeBain = sallesDeBainElement.textContent;
					} else {
						nbreSallesDeBain = null;
					}
					if (consommationEnergieElement && consommationEnergieElement.textContent) {
						consommationEnergie = consommationEnergieElement.textContent;
					} else {
						consommationEnergie = null;
					}
					if (gazEffetSerreElement && gazEffetSerreElement.textContent) {
						gazEffetSerre = gazEffetSerreElement.textContent;
					} else {
						gazEffetSerre = null;
					}
					if (consoAnnuelleElement && consoAnnuelleElement.textContent) {
						consoAnnuelleEnergie = consoAnnuelleElement.textContent;
					} else {
						consoAnnuelleEnergie = null;
					}
					if (valeurGesElement && valeurGesElement.textContent) {
						valeurGes = valeurGesElement.textContent;
					} else {
						valeurGes = null;
					}
					if (chargeEnergieMinElement && chargeEnergieMinElement.textContent) {
						chargeEnergieMin = chargeEnergieMinElement.textContent;
					} else {
						chargeEnergieMin = null;
					}
					if (chargeEnergieMaxElement && chargeEnergieMaxElement.textContent) {
						chargeEnergieMax = chargeEnergieMaxElement.textContent;
					} else {
						chargeEnergieMax = null;
					}
					if (imagesElement) {
						const imageElements = imagesElement.getElementsByTagName('IMG');
						const imagesUrls = []; // Initialiser une liste pour les URLs des images

						for (let i = 0; i < imageElements.length; i++) {
							const imageElement = imageElements[i];
							const imageUrl = imageElement.textContent; // Obtenir l'URL de l'image
							imagesUrls.push(imageUrl); // Ajouter l'URL à la liste
							images = imagesUrls;
						}

						if (imageElements.length > 0) {
							// Obtenez l'URL de la première image
							const firstImageElement = imageElements[0];
							premiereImage = firstImageElement.textContent; // ou firstImageElement.textContent, en fonction de la structure
						}
					}
				}
			}

			nbrePieces = nbrePieces && parseInt(nbrePieces) ? nbrePieces : null;
			nbreChambres = nbreChambres && parseInt(nbreChambres) ? nbreChambres : null;
			nbreSallesDeBain = nbreSallesDeBain ? nbreSallesDeBain : null;
			surfaceHabitable = surfaceHabitable && parseInt(surfaceHabitable) ? surfaceHabitable : null;
			surfaceTerrain = surfaceTerrain && parseInt(surfaceTerrain) ? surfaceTerrain : null;
			modeChauffage = modeChauffage ? modeChauffage : null;
			consommationEnergie = consommationEnergie ? consommationEnergie : null;
			gazEffetSerre = gazEffetSerre ? gazEffetSerre : null;
			consoAnnuelleEnergie = consoAnnuelleEnergie ? consoAnnuelleEnergie : null;
			valeurGes = valeurGes ? valeurGes : null;
			chargeEnergieMin = chargeEnergieMin ? chargeEnergieMin : null;
			chargeEnergieMax = chargeEnergieMax ? chargeEnergieMax : null;


			if (prixLoyer) {
				prixLoyer = parseInt(prixLoyer).toLocaleString('fr-FR');
			}

			contrat = bien.getElementsByTagName('VENTE').length > 0 ? 'VENTE' : (bien.getElementsByTagName('LOCATION').length > 0 ? 'LOCATION' : 'INCONNU');

			return {
				affId: infoGenerales.getElementsByTagName('AFF_ID')[0].textContent,
				agenceNom: agence.getElementsByTagName('AGENCE_NOM')[0].textContent,
				intitule: intituleFR,
				commentaire: commentaireFR,
				prixLoyer: prixLoyer,
				typeBien: type,
				codePostal: codePostal,
				ville: ville,
				nbrePieces: nbrePieces,
				nbreChambres: nbreChambres,
				surfaceHabitable: surfaceHabitable,
				surfaceTerrain: surfaceTerrain,
				premiereImage: premiereImage,
				images: images,
				dateCreation: dateCreation,
				reference: reference,
				type: contrat,
				modeChauffage: modeChauffage,
				nbreSallesDeBain: nbreSallesDeBain,
				numMandat: numMandat,
				typeMandat: typeMandat,
				consommationEnergie: consommationEnergie,
				gazEffetSerre: gazEffetSerre,
				consoAnnuelleEnergie: consoAnnuelleEnergie,
				valeurGes: valeurGes,
				chargeEnergieMin: chargeEnergieMin,
				chargeEnergieMax: chargeEnergieMax,
				// ...autres champs
			};
		}).filter(bien => bien !== null);

		return biensParsed;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('https://neoimo.com/script.php');
				const currentTime = new Date().toUTCString();
				localStorage.setItem('lastFetchedTime', currentTime);

				const allBiens = parseData(response.data);
				const biensForSale = filterForSale(allBiens);

				setBiens(biensForSale);
				localStorage.setItem('biensData', JSON.stringify(biensForSale));

				// Les données ont été mises à jour, mettez à jour l'état biens
				// const biensParsed = parseData(response.data);
				// setBiens(biensParsed);
				// localStorage.setItem('biensData', JSON.stringify(biensParsed));

				// Ajoutez un log pour indiquer que les données ont été mises à jour
				console.log('Données mises à jour avec succès.');
			} catch (error) {
				console.error('Erreur lors de la récupération des données depuis le backend:', error);
			}
		};

		const filterForSale = (biens) => {
			return biens.filter(bien => bien.type === 'VENTE'); // Assurez-vous que 'PRIX' correspond aux biens en vente
		}


		fetchData();
	}, []);

	const trierBiens = (tri) => {
		let biensTries;
		switch (tri) {
			case 'plus recent':
				// Supposons que 'date' est la propriété de date de bien
				biensTries = [...biens].sort((a, b) => new Date(b.dateCreation) - new Date(a.dateCreation));
				break;
			case 'plus anciens':
				biensTries = [...biens].sort((a, b) => new Date(a.dateCreation) - new Date(b.dateCreation));
				break;
			case 'prix croissant':
				biensTries = [...biens].sort((a, b) => parseFloat(a.prixLoyer) - parseFloat(b.prixLoyer));
				break;
			case 'prix décroissant':
				biensTries = [...biens].sort((a, b) => parseFloat(b.prixLoyer) - parseFloat(a.prixLoyer));
				break;
			default:
				biensTries = [...biens];
		}
		setSortedBiens(biensTries);
	};

	useEffect(() => {
		if (biens.length > 0) {
			trierBiens(triSelectionne);
		}
	}, [triSelectionne, biens]);

	const handleDropdownChange = (e) => {
		setTriSelectionne(e.target.value);
	};

	return (
		<div>
			<div className="ltn__product-area ltn__product-gutter mb-100" style={{ display: "flex", flexDirection: "column" }}>
				<div className="container mb-30" style={{ display: "flex", justifyContent: "flex-end" }}>
					<div className="row" style={{ width: "30%" }}>
						<div className="dropdown" style={{ display: "flex", justifyContent: "flex-end" }}>
							<select
								id=""
								className='nice-select'
								value={triSelectionne}
								onChange={handleDropdownChange}
								style={{ width: "50%", height: "45px", display: "block" }}
							>
								<option value="Pertinence">Pertinence</option>
								<option value="plus recent">Plus récent</option>
								<option value="plus anciens">Plus anciens</option>
								<option value="prix croissant">Prix croissant</option>
								<option value="prix décroissant">Prix décroissant</option>
							</select>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="tab-content ">
								<div className="tab-pane fade active show" id="liton_product_grid">
									<div className="ltn__product-tab-content-inner ltn__product-grid-view">
										<div className="row">
											{/* ltn__product-item */}
											{sortedBiens.map((bien, index) => {
												return (
													<div className="col-lg-4 col-sm-6 col-12" key={index}>
														<div className="ltn__blog-item ltn__blog-item-3">
															<div className="ltn__blog-img">
																<Link to={`/biens/${bien.affId}`}>
																	<img src={bien.premiereImage} alt={bien.intitule} style={{ height: "280px", width: "100%", objectFit: "cover" }} />
																</Link>
															</div>
															<div className="ltn__blog-brief">
																<div className="ltn__blog-meta">
																	<h4 style={{ height: "60px", fontWeight: 500 }}>{bien.intitule}</h4>
																</div>
																<div className="ltn__blog-meta">
																	{bien.typeBien === 'LOYER' ? (
																		<h4>LOYER: {bien.prixLoyer} €</h4>
																	) : (
																		<h4>PRIX: {bien.prixLoyer} €</h4>
																	)}
																</div>
																<div className="ltn__blog-meta">
																	<span><i className="fas fa-map-marker-alt"></i> {bien.codePostal} - {bien.ville}</span>
																</div>
																<div className="ltn__blog-meta">
																	<ul>
																		{bien.nbrePieces && (
																			<li className="ltn__blog-author">
																				<i className="fa fa-layer-group"></i> {bien.nbrePieces}
																			</li>
																		)}
																		{bien.nbreChambres && (
																			<li className="ltn__blog-tags">
																				<i className="fa fa-bed"></i> {bien.nbreChambres}
																			</li>
																		)}
																		{bien.surfaceHabitable && (
																			<li className="ltn__blog-tags">
																				<i className='fas fa-expand'></i> {bien.surfaceHabitable} m²
																			</li>
																		)}
																	</ul>
																</div>
																<div className="ltn__blog-meta-btn">
																	<div className="ltn__blog-btn">
																		<Link to={`/biens/${bien.affId}`}>Voir le détail du bien</Link>
																	</div>
																</div>
															</div>
														</div>
													</div>
													// <div className="col-lg-4 col-sm-6 col-12">
													// 	<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
													// 		<div className="ltn__blog-img">
													// 			<Link to="/blog-details"><img src={bien.premiereImage} alt="#" /></Link>
													// 		</div>
													// 		<div className="ltn__blog-brief product-info">
													// 			<div className="ltn__blog-meta">
													// 				<h4>{bien.intitule}</h4>
													// 			</div>
													// 			{/* Price */}
													// 			<div className="ltn__blog-meta">
													// 				{bien.typeBien === 'LOYER' ? (
													// 					<h4>LOYER: {bien.prixLoyer} €</h4>
													// 				) : (
													// 					<h4>PRIX: {bien.prixLoyer} €</h4>
													// 				)}
													// 			</div>
													// 			{/* Localisation with icon mapper */}
													// 			<div className="ltn__blog-meta">
													// 				<span><i class="fas fa-map-marker-alt"></i> {bien.codePostal} - {bien.ville}</span>
													// 			</div>
													// 			<div className="ltn__blog-meta">
													// 				<ul>
													// 					<li className="ltn__blog-author">
													// 						<Link to="#"><i className="far fa-user" />{bien.nbrePieces} pièces</Link>
													// 					</li>
													// 					<li className="ltn__blog-tags">
													// 						<Link to="#"><i className="fas fa-tags" />{bien.nbreChambres} chambres</Link>
													// 						<Link to="#"><i className="fas fa-tags" />{bien.surfaceHabitable} m<sup>2</sup></Link>
													// 					</li>
													// 				</ul>
													// 			</div>
													// 			{/* <h3 className="ltn__blog-title"><Link to="/blog-details">10 Brilliant Ways To Decorate Your Home</Link></h3> */}
													// 			<div className="ltn__blog-meta-btn">
													// 				<div className="ltn__blog-btn">
													// 					<Link to="/blog-details">Voir le détail du bien</Link>
													// 				</div>
													// 			</div>
													// 		</div>
													// 	</div>
													// </div>
												)
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ShopGridV1