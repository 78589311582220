import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const TeamV1 = () => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	let imagealt = 'image'
	const location = useLocation();

	useEffect(() => {
		// Si l'URL contient un hash
		if (location.hash) {
			const id = location.hash.replace('#', '');
			const element = document.getElementById(id);
			if (element) {
				// Faire défiler jusqu'à l'élément
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}
		// Se déclenche à chaque changement de l'objet location
	}, [location]);

	return (
		<div className="ltn__team-area pt-30 pb-90 go-top" id='equipe'>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<div className="section-title-area ltn__section-title-2--- text-center">
								<img src={publicUrl + "assets/img/banner/equipe-neoimo.png"} alt="#" className='pb-60' />
							</div>
						</div>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-lg-6 col-sm-6">
						<div className="ltn__team-item ltn__team-item-3---">
							<div className="team-img" style={{ padding: 40 }}>
								<img src={publicUrl + "assets/img/luca-neoimo.jpeg"} alt="Image" style={{ height: "150px", width: "auto", objectFit: "contain", borderRadius: "999px", filter: "brightness(125%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg)" }} />
							</div>
							<div className="team-info">
								<h4><Link to="">Luca BIBLOT</Link></h4>
								<span style={{ padding: "", textAlign: "justify" }}>Gérant de NÉO IMO. Néocastrien et titulaire d’une licence “transaction et gestion immobilière”, il bénéfice de quatre années d’expérience dans l’immobilier à Annecy.</span>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-sm-6">
						<div className="ltn__team-item ltn__team-item-3---">
							<div className="team-img" style={{ padding: 40 }}>
								<img src={publicUrl + "assets/img/johnny-neoimo.webp"} alt="Image" style={{ height: "150px", width: "auto", objectFit: "contain", borderRadius: "999px" }} />
							</div>
							<div className="team-info">
								<h4><Link to="">Johnny NOEL</Link></h4>
								<span style={{ textAlign: "justify" }}>Conseiller immobilier depuis plus de 18 ans à Neufchâteau, l’un des plus anciens localement, il a participé à plus de 1000 transactions.</span> <br /><br />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TeamV1