import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactInfo extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__contact-address-area mb-100" style={{ marginTop: "-450px", zIndex: 99, position: "relative" }}>
			<div className="container">
				<div className="row" style={{ background: "#AC2E31", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 99, color: "white", padding: "35px 50px" }}>
					<div className="col-lg-3" style={{ textAlign: "end" }}>
						<h3 style={{ color: 'white', margin: 0 }}>Besoin d'aide ?</h3>
					</div>
					<div className="col-lg-4" style={{ display: "flex", justifyContent: "flex-end" }}>
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow" style={{ padding: 0, margin: "10px 0px", border: "none" }}>
							<ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
								<li style={{ margin: 0, border: "none" }}><a href="mailto:info@neoimo.com" style={{ fontSize: 16, padding: "20px 30px", background: "#FFF", borderRadius: "99px", color: "black" }}><i className="icon-phone" style={{ color: "#AC2E31", fontSize: 16, marginRight: 8 }} />03.29.94.18.00</a></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-4" style={{ display: "flex", justifyContent: "flex-start" }}>
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow" style={{ padding: 0, margin: "10px 0px", border: "none" }}>
							<ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
								<li style={{ margin: 0 }}><a href="mailto:info@neoimo.com" style={{ fontSize: 16, padding: "20px 30px", background: "#FFF", borderRadius: "99px", color: "black" }}><i className="icon-mail" style={{ color: "#AC2E31", fontSize: 16, marginRight: 8 }} />info@neoimo.com</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default ContactInfo