import React, { useState } from 'react';
// import './style.css'

const Modal = ({ searchCriteria, setSearchCriteria, closeModal, onSearch }) => {
    const [criteria, setCriteria] = useState({
        reference: '',
        prixMin: '',
        prixMax: '',
        pieceMin: '',
        pieceMax: '',
        surfaceMax: '',
        terrainMin: '',
        terrainMax: '',
        chambresMin: '',
        chambresMax: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('criteria', criteria);
        // onSearch(criteria);
        closeModal();
    };

    const handleChange = (e) => {
        console.log('e', e.target.id, e.target.value);
        setCriteria({ ...criteria, [e.target.id]: e.target.value });
        setSearchCriteria({ ...searchCriteria, [e.target.id]: e.target.value });
    };

    const handleReset = () => {
        setSearchCriteria({
            reference: '',
            prixMin: '',
            prixMax: '',
            pieceMin: '',
            pieceMax: '',
            surfaceMax: '',
            terrainMin: '',
            terrainMax: '',
            chambresMin: '',
            chambresMax: '',
        });
    };

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Plus de critères</h2>
                    <span className="close-modal" onClick={closeModal}>&times;</span>
                </div>
                <form className="modal-form" onSubmit={handleSubmit} onReset={handleReset}>
                    {/* Formulaire avec deux colonnes */}
                    <div className="form-column">
                        <label htmlFor="reference">Référence</label>
                        <input type="text" placeholder="" id="reference" value={searchCriteria.reference || ''} onChange={handleChange} />
                        <label htmlFor="pieceMin">Nombre de pièces min</label>
                        <input type="number" placeholder="" id='pieceMin'
                            value={searchCriteria.pieceMin}
                            onChange={handleChange} />
                        <label htmlFor="surfaceMax">Surface max</label>
                        <input type="number" placeholder="" id='surfaceMax'
                            value={searchCriteria.surfaceMax}
                            onChange={handleChange} />
                        <label htmlFor="terrainMax">Surface terrain max</label>
                        <input type="number" placeholder="" id='terrainMax'
                            value={searchCriteria.terrainMax}
                            onChange={handleChange} />
                        <label htmlFor="chambresMin">Nombre de chambres min</label>
                        <input type="number" placeholder="" id='chambresMin'
                            value={searchCriteria.chambresMin}
                            onChange={handleChange} />
                    </div>
                    <div className="form-column">
                        <label htmlFor="prixMin">Prix min</label>
                        <input type="number" placeholder="" id='prixMin'
                            value={searchCriteria.prixMin}
                            onChange={handleChange} />
                        <label htmlFor="pieceMax">Nombre de pièces max</label>
                        <input type="number" placeholder="" id='pieceMax'
                            value={searchCriteria.pieceMax}
                            onChange={handleChange} />
                        <label htmlFor="terrainMin">Surface terrain min</label>
                        <input type="number" placeholder="" id='terrainMin'
                            value={searchCriteria.terrainMin}
                            onChange={handleChange} />
                        <label htmlFor="chambresMax">Nombre de chambres max</label>
                        <input type="number" placeholder="" id='chambresMax'
                            value={searchCriteria.chambresMax}
                            onChange={handleChange} />
                    </div>
                    <div className="form-actions">
                        <button type="button" className="btn" style={{backgroundColor: "#AC2E31", color: "#FFF", width: "45%", margin: 0}} onClick={closeModal}>Terminer</button>
                        <button type="reset" className="btn btn-alt" onClick={handleReset}>Réinitialiser les critères</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Modal