import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const ShopGridSearch = (bien) => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	const [biens, setBiens] = useState([]);
	const [sortedBiens, setSortedBiens] = useState([]);
	const [triSelectionne, setTriSelectionne] = useState('Pertinence');

	const location = useLocation();
	const [filteredBiens, setFilteredBiens] = useState([]);

	// Fonctions de filtrage modulaires
	const filterByTypeBien = (criteria) => (bien) => {
		if (!bien.typeBien) return true; // skip if typeBien is null or undefined
		return !criteria.typeBien || bien.typeBien === criteria.typeBien;
	};

	const filterByPrixMax = (criteria) => (bien) => {
		if (!bien.prixLoyer) return true; // skip if prixLoyer is null or undefined
		const cleanPrixLoyer = parseInt(bien.prixLoyer.replace(/\D/g, ''), 10);
		return !criteria.prixMax || cleanPrixLoyer <= parseInt(criteria.prixMax.replace(/\D/g, ''), 10);
	};


	const filterByPrixMin = (criteria) => (bien) => {
		if (!bien.prixLoyer) return true; // skip if prixLoyer is null or undefined
		const cleanPrixLoyer = parseInt(bien.prixLoyer.replace(/\D/g, ''), 10);
		return !criteria.prixMin || cleanPrixLoyer >= parseInt(criteria.prixMin.replace(/\D/g, ''), 10);
	};

	const filterBySurfaceMin = (criteria) => (bien) => {
		if (!bien.surfaceHabitable) return true; // skip if surfaceHabitable is null or undefined
		const cleanSurfaceHabitable = parseInt(bien.surfaceHabitable.replace(/\s/g, ''), 10);
		return !criteria.surfaceMin || cleanSurfaceHabitable >= parseInt(criteria.surfaceMin.replace(/\s/g, ''), 10);
	};

	const filterByCodePostal = (criteria) => (bien) => {
		// Exclude the item if codePostal is required but not present in the bien
		if (criteria.villeCodePostal && !bien.codePostal && !bien.ville) return true;

		const codePostalVille = bien.codePostal + ' - ' + bien.ville;

		return !criteria.villeCodePostal || codePostalVille, criteria.villeCodePostal.toUpperCase(), codePostalVille.toUpperCase().includes(criteria.villeCodePostal.toUpperCase())
	};

	const filterByReference = (criteria) => (bien) => {
		if (!bien.affId) return true; // skip if affId is null or undefined

		return !criteria.reference || bien.reference.toUpperCase().includes(criteria.reference.toUpperCase());
	};

	const filterByPieceMin = (criteria) => (bien) => {
		if (!bien.nbrePieces) return true; // skip if nbrePieces is null or undefined
		const cleanNbrePieces = parseInt(bien.nbrePieces.replace(/\D/g, ''), 10);
		return !criteria.pieceMin || cleanNbrePieces >= parseInt(criteria.pieceMin.replace(/\D/g, ''), 10);
	};

	const filterByPieceMax = (criteria) => (bien) => {
		if (!bien.nbrePieces) return true; // skip if nbrePieces is null or undefined
		const cleanNbrePieces = parseInt(bien.nbrePieces.replace(/\D/g, ''), 10);
		return !criteria.pieceMax || cleanNbrePieces <= parseInt(criteria.pieceMax.replace(/\D/g, ''), 10);
	};

	const filterBySurfaceMax = (criteria) => (bien) => {
		if (!bien.surfaceHabitable) return true; // skip if surfaceHabitable is null or undefined
		const cleanSurfaceHabitable = parseInt(bien.surfaceHabitable.replace(/\s/g, ''), 10);
		return !criteria.surfaceMax || cleanSurfaceHabitable <= parseInt(criteria.surfaceMax.replace(/\s/g, ''), 10);
	};

	const filterByTerrainMin = (criteria) => (bien) => {
		if (!bien.surfaceTerrain) return true; // skip if surfaceTerrain is null or undefined
		const cleanSurfaceTerrain = parseInt(bien.surfaceTerrain.replace(/\s/g, ''), 10);
		return !criteria.terrainMin || cleanSurfaceTerrain >= parseInt(criteria.terrainMin.replace(/\s/g, ''), 10);
	};

	const filterByTerrainMax = (criteria) => (bien) => {
		if (!bien.surfaceTerrain) return true; // skip if surfaceTerrain is null or undefined
		const cleanSurfaceTerrain = parseInt(bien.surfaceTerrain.replace(/\s/g, ''), 10);
		return !criteria.terrainMax || cleanSurfaceTerrain <= parseInt(criteria.terrainMax.replace(/\s/g, ''), 10);
	};

	const filterByChambresMin = (criteria) => (bien) => {
		if (!bien.nbreChambres) return true; // skip if nbreChambres is null or undefined
		const cleanNbreChambres = parseInt(bien.nbreChambres.replace(/\D/g, ''), 10);
		return !criteria.chambresMin || cleanNbreChambres >= parseInt(criteria.chambresMin.replace(/\D/g, ''), 10);
	};

	const filterByChambresMax = (criteria) => (bien) => {
		if (!bien.nbreChambres) return true; // skip if nbreChambres is null or undefined
		const cleanNbreChambres = parseInt(bien.nbreChambres.replace(/\D/g, ''), 10);
		return !criteria.chambresMax || cleanNbreChambres <= parseInt(criteria.chambresMax.replace(/\D/g, ''), 10);
	};

	// Combinez les fonctions de filtrage
	const applyFilters = (biens, criteria) => {
		return biens
			.filter(filterByChambresMax(criteria))
			.filter(filterByChambresMin(criteria))
			.filter(filterByTerrainMax(criteria))
			.filter(filterByTerrainMin(criteria))
			.filter(filterBySurfaceMax(criteria))
			.filter(filterByPieceMax(criteria))
			.filter(filterByPieceMin(criteria))
			.filter(filterByReference(criteria))
			.filter(filterBySurfaceMin(criteria))
			.filter(filterByPrixMin(criteria))
			.filter(filterByPrixMax(criteria))
			.filter(filterByTypeBien(criteria))
			.filter(filterByCodePostal(criteria));
	};

	useEffect(() => {
		const savedBiens = localStorage.getItem('biensData');
		let biensParsed = savedBiens ? JSON.parse(savedBiens) : [];

		if (location.state && location.state.searchCriteria) {
			const searchCriteria = location.state.searchCriteria;
			console.log("searchCriteria222222", searchCriteria);

			const results = applyFilters(biensParsed, searchCriteria);
			console.log("results", results);
			setFilteredBiens(results);
		}
	}, [location.state]);

	useEffect(() => {
		const fetchData = () => {
			const xhr = new XMLHttpRequest();
			// xhr.open('GET', 'https://clients.immo-facile.com/office8/imogroup_neufchateau/cache/export.xml', false,);
			xhr.open('GET', 'http://neoimo.square-com.fr/formatted.xml', false,);

			xhr.onload = () => {
				if (xhr.status === 200) {
					const xmlDoc = xhr.responseXML;
					const biensXML = xmlDoc.getElementsByTagName('BIEN');
					const currentTime = new Date().toUTCString();
					// localStorage.setItem('lastFetchedTime', currentTime);

					const biensParsed = Array.from(biensXML).map(bien => {

						const infoGenerales = bien.getElementsByTagName('INFO_GENERALES')[0];
						const agence = bien.getElementsByTagName('AGENCE')[0];

						const dateCreation = infoGenerales.getElementsByTagName('DATE_CREATION')[0]?.textContent || 'Date inconnue';

						const intituleElement = bien.getElementsByTagName('INTITULE')[0];
						const intituleFR = intituleElement ? intituleElement.getElementsByTagName('FR')[0].textContent : '';

						const localisation = bien.getElementsByTagName('LOCALISATION')[0];
						const codePostal = localisation.getElementsByTagName('CODE_POSTAL')[0]?.textContent || 'Non spécifié';
						const ville = localisation.getElementsByTagName('VILLE')[0]?.textContent || 'Non spécifié';

						let prixLoyer;
						let typeBien;


						if (bien.getElementsByTagName('VENTE').length > 0) {
							const vente = bien.getElementsByTagName('VENTE')[0];
							prixLoyer = vente.getElementsByTagName('PRIX')[0]?.textContent || 'Non spécifié';
							typeBien = 'PRIX';
						} else if (bien.getElementsByTagName('LOCATION').length > 0) {
							const location = bien.getElementsByTagName('LOCATION')[0];
							prixLoyer = location.getElementsByTagName('LOYER')[0]?.textContent || 'Non spécifié';
							typeBien = 'LOYER';
						} else {
							prixLoyer = 'Information indisponible';
							typeBien = 'Indéfini';
						}

						let nbrePieces = null;
						let nbreChambres = null;
						let surfaceHabitable = null;
						let surfaceTerrain = null;
						let premiereImage = null;

						const typesBiens = [
							'AGRICOLE_VITICOLE',
							'APPARTEMENT',
							'CAVE',
							'DEMEURE',
							'FOND_COMMERCE',
							'FORET',
							'GRANGE',
							'IMMEUBLE',
							'LOCAL_PROFESSIONNEL',
							'MAISON',
							'MARINA',
							'PARKING',
							'PROGRAMME_NEUF',
							'TERRAIN'
						];

						for (const typeBien of typesBiens) {
							if (bien.getElementsByTagName(typeBien).length > 0) {
								const typeElement = bien.getElementsByTagName(typeBien)[0];
								const piecesElement = typeElement.getElementsByTagName('NBRE_PIECES')[0];
								const chambresElement = typeElement.getElementsByTagName('NBRE_CHAMBRES')[0];
								const surfaceElement = typeElement.getElementsByTagName('SURFACE_HABITABLE')[0];
								const imagesElement = bien.getElementsByTagName('IMAGES')[0];

								if (piecesElement) {
									nbrePieces = piecesElement.textContent;
								}
								if (chambresElement) {
									nbreChambres = chambresElement.textContent;
								}
								if (surfaceElement) {
									surfaceHabitable = surfaceElement.textContent;
								}
								if (imagesElement) {
									const imageElements = imagesElement.getElementsByTagName('IMG');
									if (imageElements.length > 0) {
										// Obtenez l'URL de la première image
										const firstImageElement = imageElements[0];
										premiereImage = firstImageElement.textContent; // ou firstImageElement.textContent, en fonction de la structure
									}
								}
							}
						}

						nbrePieces = nbrePieces && parseInt(nbrePieces) ? nbrePieces : null;
						nbreChambres = nbreChambres && parseInt(nbreChambres) ? nbreChambres : null;
						surfaceHabitable = surfaceHabitable && parseInt(surfaceHabitable) ? surfaceHabitable : null;
						surfaceTerrain = surfaceTerrain && parseInt(surfaceTerrain) ? surfaceTerrain : null;

						if (prixLoyer) {
							prixLoyer = parseInt(prixLoyer).toLocaleString('fr-FR');
						}

						return {
							affId: infoGenerales.getElementsByTagName('AFF_ID')[0].textContent,
							agenceNom: agence.getElementsByTagName('AGENCE_NOM')[0].textContent,
							intitule: intituleFR,
							prixLoyer: prixLoyer,
							typeBien: typeBien,
							codePostal: codePostal,
							ville: ville,
							nbrePieces: nbrePieces,
							nbreChambres: nbreChambres,
							surfaceHabitable: surfaceHabitable,
							surfaceTerrain: surfaceTerrain,
							premiereImage: premiereImage,
							dateCreation: dateCreation
							// ...autres champs
						};
					}).filter(bien => bien !== null);

					setBiens(biensParsed);
					// localStorage.setItem('biensData', JSON.stringify(biensParsed));
				} else if (xhr.status === 304) {
					console.log('Pas de modification détectée depuis la dernière récupération.');
				} else {
					console.error('Erreur lors de la récupération du fichier XML');
				}
			};

			xhr.onerror = () => {
				console.error('Erreur réseau lors de la requête XML');
			};

			xhr.send();
		}

		// const savedBiens = localStorage.getItem('biensData');
		// if (savedBiens) {
		//     setBiens(JSON.parse(savedBiens));
		// } else {
		// }
		// fetchData();

		// const scheduleFetch = () => {
		//     const now = new Date();
		//     const nextTimes = [new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0, 0, 0),
		//     new Date(now.getFullYear(), now.getMonth(), now.getDate(), 11, 36, 0, 0),
		//     new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16, 59, 0, 0),
		//     new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0, 0, 0)].filter(time => time > now);

		//     if (nextTimes.length > 0) {
		//         const msUntilNextTime = nextTimes[0].getTime() - now.getTime();
		//         setTimeout(() => {
		//             fetchData();
		//             setInterval(fetchData, 24 * 60 * 60 * 1000); // Répétez chaque jour
		//         }, msUntilNextTime);
		//     }
		// };

		// scheduleFetch();
		// setBiens(JSON.parse(localStorage.getItem('biensData')));
	}, []);

	return (
		<div>
			<div className="ltn__product-area ltn__product-gutter mt-100 mb-100" style={{ display: "flex", flexDirection: "column" }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="tab-content ">
								<div className="tab-pane fade active show" id="liton_product_grid">
									<div className="ltn__product-tab-content-inner ltn__product-grid-view">
										<div className="row">
											{/* ltn__product-item */}
											{filteredBiens.map((bien, index) => {
												return (
													<div className="col-lg-4 col-sm-6 col-12" key={index}>
														<div className="ltn__blog-item ltn__blog-item-3">
															<div className="ltn__blog-img">
																<Link to={`/biens/${bien.affId}`}>
																	<img src={bien.premiereImage} alt={bien.intitule} style={{ height: "280px", width: "100%", objectFit: "cover" }} />
																</Link>
															</div>
															<div className="ltn__blog-brief">
																<div className="ltn__blog-meta">
																	<h4 style={{ height: "60px", fontWeight: 500 }}>{bien.intitule}</h4>
																</div>
																<div className="ltn__blog-meta">
																	{bien.typeBien === 'LOYER' ? (
																		<h4>LOYER: {bien.prixLoyer} €</h4>
																	) : (
																		<h4>PRIX: {bien.prixLoyer} €</h4>
																	)}
																</div>
																<div className="ltn__blog-meta">
																	<span><i className="fas fa-map-marker-alt"></i> {bien.codePostal} - {bien.ville}</span>
																</div>
																<div className="ltn__blog-meta">
																	<ul>
																		{bien.nbrePieces && (
																			<li className="ltn__blog-author">
																				<i className="fa fa-layer-group"></i> {bien.nbrePieces}
																			</li>
																		)}
																		{bien.nbreChambres && (
																			<li className="ltn__blog-tags">
																				<i className="fa fa-bed"></i> {bien.nbreChambres}
																			</li>
																		)}
																		{bien.surfaceHabitable && (
																			<li className="ltn__blog-tags">
																				<i className='fas fa-expand'></i> {bien.surfaceHabitable} m²
																			</li>
																		)}
																	</ul>
																</div>
																<div className="ltn__blog-meta-btn">
																	<div className="ltn__blog-btn">
																		<Link to={`/biens/${bien.affId}`}>Voir le détail du bien</Link>
																	</div>
																</div>
															</div>
														</div>
													</div>
													// <div className="col-lg-4 col-sm-6 col-12">
													// 	<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
													// 		<div className="ltn__blog-img">
													// 			<Link to="/blog-details"><img src={bien.premiereImage} alt="#" /></Link>
													// 		</div>
													// 		<div className="ltn__blog-brief product-info">
													// 			<div className="ltn__blog-meta">
													// 				<h4>{bien.intitule}</h4>
													// 			</div>
													// 			{/* Price */}
													// 			<div className="ltn__blog-meta">
													// 				{bien.typeBien === 'LOYER' ? (
													// 					<h4>LOYER: {bien.prixLoyer} €</h4>
													// 				) : (
													// 					<h4>PRIX: {bien.prixLoyer} €</h4>
													// 				)}
													// 			</div>
													// 			{/* Localisation with icon mapper */}
													// 			<div className="ltn__blog-meta">
													// 				<span><i class="fas fa-map-marker-alt"></i> {bien.codePostal} - {bien.ville}</span>
													// 			</div>
													// 			<div className="ltn__blog-meta">
													// 				<ul>
													// 					<li className="ltn__blog-author">
													// 						<Link to="#"><i className="far fa-user" />{bien.nbrePieces} pièces</Link>
													// 					</li>
													// 					<li className="ltn__blog-tags">
													// 						<Link to="#"><i className="fas fa-tags" />{bien.nbreChambres} chambres</Link>
													// 						<Link to="#"><i className="fas fa-tags" />{bien.surfaceHabitable} m<sup>2</sup></Link>
													// 					</li>
													// 				</ul>
													// 			</div>
													// 			{/* <h3 className="ltn__blog-title"><Link to="/blog-details">10 Brilliant Ways To Decorate Your Home</Link></h3> */}
													// 			<div className="ltn__blog-meta-btn">
													// 				<div className="ltn__blog-btn">
													// 					<Link to="/blog-details">Voir le détail du bien</Link>
													// 				</div>
													// 			</div>
													// 		</div>
													// 	</div>
													// </div>
												)
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ShopGridSearch