import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const ServiceV5 = () => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	const location = useLocation();

	useEffect(() => {
		// Si l'URL contient un hash
		if (location.hash) {
			const id = location.hash.replace('#', '');
			const element = document.getElementById(id);
			if (element) {
				// Faire défiler jusqu'à l'élément
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}
		// Se déclenche à chaque changement de l'objet location
	}, [location]);

	return (
		<div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<div className="section-title-area ltn__section-title-2--- text-center">
								<img src={publicUrl + "assets/img/banner/services-neoimo.png"} alt="#" className='pb-60' />
							</div>
						</div>
					</div>
				</div>
				<div className="row  justify-content-center" id='services'>
					<div className="col-lg-6 col-sm-6 col-12">
						<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
							<div className="ltn__feature-info" style={{ textAlign: "justify" }}>
								<h3 style={{ color: "#AC2E31" }}><Link to="#">L'ESTIMATION</Link></h3>
								<hr style={{ padding: 0, margin: "10px 0px 20px 0px", width: "20%", background: "red", height: 5, borderRadius: 16 }} />
								<span>Rencontrez-nous pour une évaluation immobilière détaillée, basée sur notre expertise locale et notre étude approfondie des caractéristiques techniques de votre bien.</span>
								<ul>
									<li>Rendez-vous personnalisé, pour vous rencontrer afin d'échanger et faire le point sur votre projet immobilier et votre bien.</li>
									<li>Étude d'informations et caractéristiques techniques de votre bien.</li>
									<li>Estimation réelle et précise de votre bien grâce à notre expérience locale depuis de nombreuses années.</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-sm-6 col-12">
						<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
							<div className="ltn__feature-info" style={{ textAlign: "justify" }}>
								<h3 style={{ color: "#AC2E31" }}><Link to="#">COMMERCIALISATION</Link></h3>
								<hr style={{ padding: 0, margin: "10px 0px 20px 0px", width: "20%", background: "red", height: 5, borderRadius: 16 }} />
								<span>Valorisation, communication et visites ciblées pour vendre votre bien rapidement et efficacement.</span>
								<ul>
									<li>Nous définissons ensemble le prix de mise en vente de votre bien et nous vous accompagnons dans le choix du mandat de vente le mieux adapté à vos besoins !</li>
									<li>Constitution du dossier administratif.</li>
									<li>Valorisation et communication optimale sur votre bien : photos HDR, visite virtuelle, communication et diffusion sur nos différents supports de communication, et relance du fichier acquéreur.</li>
									<li>Visites organisées et ciblées (étude au préalable de la solvabilité de nos acquéreurs)</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-sm-6 col-12">
						<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
							<div className="ltn__feature-info" style={{ textAlign: "justify" }}>
								<h3 style={{ color: "#AC2E31" }}><Link to="#">LA VENTE</Link></h3>
								<hr style={{ padding: 0, margin: "10px 0px 20px 0px", width: "20%", background: "red", height: 5, borderRadius: 16 }} />
								<span>De la rédaction de l'offre d'achat à la signature chez le notaire, nous vous accompagnons à chaque étape pour une vente sans soucis et la remise des clés</span>
								<ul>
									<li>Lorsque nous aurons trouvé ensemble votre bonheur, nous vous accompagnerons dans la rédaction de l’offre d’achat.</li>
									<li>Préparation et signature du compromis de vente.</li>
									<li>Possibilité d’optimisation et de conseil financier.</li>
									<li>Accompagnement jusqu’à la signature définitive chez le notaire et la remise des clés.</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-sm-6 col-12">
						<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
							<div className="ltn__feature-info" style={{ textAlign: "justify" }}>
								<h3 style={{ color: "#AC2E31" }}><Link to="#">UN SUIVI RÉGULIER</Link></h3>
								<hr style={{ padding: 0, margin: "10px 0px 20px 0px", width: "20%", background: "red", height: 5, borderRadius: 16 }} />
								<span>Vendeur ou acquéreur, nous vous offrons un suivi personnalisé pour répondre à vos besoins et trouver la propriété idéale ensemble.</span>
								<ul>
									<li>Vous êtes vendeur : nous assurons un suivi régulier à votre convenance, au rythme que vous souhaitez.</li>
									<li>Vous êtes acquéreur : nous vous recevons à l'agence, afin d’échanger sur votre projet, vos critères de recherches et trouver ensemble le bien qui vous correspond !</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ServiceV5