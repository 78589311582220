import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutNeoimo extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pt-120 pb-90 mt--30 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl + "assets/img/logoCompact-neoimo.png"} alt="About Us Image" style={{ height: "350px", width: "100%", objectFit: "cover" }} />
						</div>
					</div>
					<div className="col-lg-6 align-self-center" style={{ border: "1px solid black", padding: "50px 50px 20px 30px", borderRadius: 12, boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)" }}>
						<div className="section-title-area ltn__section-title-2--- text-center" style={{ position: "absolute", top: "-50px", marginBottom: "0px", transform: "rotate(7deg)", left: "80px" }}>
							<img src={publicUrl + "assets/img/banner/propos-neoimo.png"} alt="#" className='' style={{ height: "85px", width: "100%", objectFit: "cover", paddingBottom: 0 }} />
						</div>
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h1 className="section-title">NEO IMO</h1>
								<h5>48 Rue Saint-Jean, 88300 Neufchâteau</h5>

								<div className="ltn__social-media">
									<ul>
										<li style={{ cursor: "pointer" }}><a href="https://www.facebook.com/NEOIMONEUFCHATEAU/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" style={{ background: "white", borderRadius: 99, padding: "4px 8px 4px 8px", color: "#FFF", cursor: "pointer", backgroundColor: "#AC2E31", marginRight: "5px" }} />Suivez-nous sur Facebook !</a></li>
									</ul>
								</div>

								<h6 className='mt-20' style={{ color: "#AC2E31" }}>Retrouvez les avis de nos clients :</h6>

								<a href='https://www.opinionsystem.fr/fr-fr/certificate/10742?referer=1' target="_blank">
									<div
										className="os-row"
										style={{ cursor: 'pointer' }}
										login_url="http://www.opinionsystem.fr/company_result.php?company_id=10742&amp;referer=1"
										title="Indice de satisfaction: 94.17%"
									>
										<div className="os-col-xs-4">
											<div
												className="star-off"
												style={{
													textAlign: 'left',
													display: 'inline-block',
													minWidth: '90px',
													maxWidth: '90px',
													height: '32px',
													verticalAlign: 'top',
													marginTop: '2px',
													lineHeight: '32px'
												}}
											>
												<div
													className="star-on"
													style={{
														display: 'inline-block',
														height: '32px',
														width: '94.17%',
														lineHeight: '32px'
													}}
												></div>
											</div>
										</div>
										<div className="os-col-xs-8">
											<div style={{ textAlign: 'right' }}>
												<span
													style={{
														display: 'inline-block',
														position: 'relative',
														top: '18px',
														fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
														fontStyle: 'normal',
														fontSize: '11px',
														verticalAlign: 'top',
														textAlign: 'right',
														lineHeight: '11px',
														color: '#000000'
													}}
												>
													<span style={{ textDecoration: 'underline' }}>47&nbsp;Avis</span>&nbsp;
												</span>
												<span
													style={{
														display: 'inline-block',
														lineHeight: '32px',
														textAlign: 'left',
														width: '32px',
														height: '32px'
													}}
													className="os-rating-logo"
												></span>
											</div>
										</div>
									</div>
								</a>

								<hr style={{ height: 2, width: "100%", marginTop: "80px" }}></hr>

								{/* PHONE TEL WITH ICON */}
								<div className="ltn__top-bar-menu">
									<ul>
										<li><a href="mailto:info@neoimo.com?Subject=Flower%20greetings%20to%20you" style={{ fontSize: 16 }}><i className="icon-phone" style={{ color: "#AC2E31", fontSize: 16 }} />Tél: 03.29.94.18.00</a></li>
									</ul>
								</div>

								{/* Open hours */}
								<div className="ltn__top-bar-menu" style={{ marginTop: 20 }}>
									<ul>
										<li style={{ fontSize: 16 }}><i className="icon-calendar" style={{ color: "#AC2E31", fontSize: 16 }} />Du lundi au vendredi : 08h30 – 12h00 / 13h30 – 18h00</li>
										<li style={{ marginLeft: 20, fontSize: 16 }}>Le samedi : Sur rendez-vous</li>
									</ul>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default AboutNeoimo