import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV4 from './components/home-v4';
import About from './components/about';
import Service from './components/service';
import ShopGrid from './components/shop-grid';
import Contact from './components/contact';
import History from './components/history';
import ShopLouer from './components/shop-louer';
import AboutV2 from './components/aboutv2';
import ShopSearch from './components/shop-search';
import SaleProperty from './components/sale-property';
import MentionLegal from './components/mention-legal';
import PolitiqueConfidentialite from './components/politique-confidentialite';


class Root extends Component {
    render() {
        return (
            <HashRouter basename="/">
                <div>
                    <Switch>
                        <Route exact path="/" component={HomeV4} />
                        <Route path="/acheter" component={ShopGrid} />
                        <Route path="/vendre" component={History} />
                        <Route path="/louer" component={ShopLouer} />
                        <Route path="/logements-neufs" component={About} />
                        <Route path="/notre-agence" component={AboutV2} />
                        <Route path="/nos-services" component={Service} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/recherche" component={ShopSearch} />
                        <Route path="/biens/:id" component={SaleProperty} />
                        <Route path="/mentions-legales" component={MentionLegal} />
                        <Route path="/politique-de-confidentialite" component={PolitiqueConfidentialite} />
                    </Switch>
                </div>
            </HashRouter>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
