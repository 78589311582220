import React, { Component, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import emailjs from '@emailjs/browser';


const ContactForm = () => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	const form = useRef();
	const [formMessage, setFormMessage] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_0nr60tt', 'template_out5atl', form.current, '8auszsYUb9pNg9_Fp')
			.then((result) => {
				console.log(result.text);
				setFormMessage('Votre message a été envoyé avec succès.');
				setIsSuccess(true);
				form.current.reset();
			}, (error) => {
				console.log(error.text);
				setFormMessage('Une erreur est survenue lors de l\'envoi de votre message.');
				setIsSuccess(false);
			});
	};

	return <div className="ltn__contact-message-area mb-120">
		<div className="container">
			<div className="section-title-area ltn__section-title-2--- text-center mb-100" >
				<img src={publicUrl + "assets/img/banner/contact-neoimo.png"} alt="#" className='' />
			</div>
			<div className="row">
				<div className="col-lg-12">
					<div className="ltn__form-box contact-form-box box-shadow white-bg">
						<form id="contact-form" ref={form} onSubmit={sendEmail}>
							<div className="row">
								<div className="col-md-6">
									<div className="input-item input-item-name ltn__custom-icon">
										<input type="text" name="from_name" placeholder="Nom" required />
									</div>
								</div>
								<div className="col-md-6">
									<div className="input-item input-item-name ltn__custom-icon">
										<input type="text" name="from_firstname" placeholder="Prénom" required />
									</div>
								</div>
								<div className="col-md-6">
									<div className="input-item input-item-email ltn__custom-icon">
										<input type="email" name="from_email" placeholder="Email" required />
									</div>
								</div>
								<div className="col-md-6">
									<div className="input-item input-item-phone ltn__custom-icon">
										<input type="text" name="from_phone" placeholder="Téléphone" required />
									</div>
								</div>
								<div className="col-md-6">
									<div className="input-item input-ville-name ltn__custom-icon">
										<input type="text" name="from_city" placeholder="Ville" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="input-item input-pays-icon ltn__custom-icon">
										<input type="text" name="from_country" placeholder="Pays" />
									</div>
								</div>
							</div>
							<div className="input-item input-item-textarea ltn__custom-icon">
								<textarea name="message" placeholder="Message" defaultValue={""} required />
							</div>
							<p className={`form-message mb-20 mt-0 ${isSuccess ? 'success-message' : 'error-message'}`}>
								{formMessage}
							</p>
							<div className="btn-wrapper mt-0">
								<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">ENVOYER</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default ContactForm