const GasFile = ({ valueGES, displayDPE, displayInline, shortcode }) => {
    let classGes = 'wpdpeges';
    let classSC = '';
    let classBlank = '';

    if (displayInline) {
        classGes += !shortcode ? ' ges-inline' : '';
        classSC += shortcode ? ' ges-inline' : '';
    }

    classBlank = !valueGES ? ' ges-blank-active' : '';

    const getLetterGes = (value) => {
        if (value <= 5) return 'A';
        else if (value <= 10) return 'B';
        else if (value <= 20) return 'C';
        else if (value <= 35) return 'D';
        else if (value <= 55) return 'E';
        else if (value <= 80) return 'F';
        else return 'G';
    };

    const lettreGes = getLetterGes(valueGES);

    return (
        <div className={classGes}>
            <div className={`ges ${classSC} ${classBlank}`}>
                <span className="title">Emission de Gaz à Effet de Serre
                </span>
                <figure class="emission emission-ges">
                    <figcaption class="emission-title hidden">
                        Emission de Gaz à Effet de Serre
                    </figcaption>
                    <div class="emission-container " aria-hidden="true">
                        <div class="emission-legend"><span>
                            Faible émission de GES
                        </span> <span><abbr title="Quantity of greenhouse gas emissions">
                            KgéqCO2 / m².an
                        </abbr></span></div>
                        <div class="emission-diagram">
                            <div class="emission-line line-a"><span class="emission-value">
                                ≤ 5
                            </span><span class="emission-letter letter-a">A</span></div>
                            <div class="emission-line line-b"><span class="emission-value">
                                6 à 10
                            </span><span class="emission-letter letter-b">B</span></div>
                            <div class="emission-line line-c"><span class="emission-value">
                                11 à 20
                            </span><span class="emission-letter letter-c">C</span></div>
                            <div class="emission-line line-d"><span class="emission-value">
                                21 à 35
                            </span><span class="emission-letter letter-d">D</span></div>
                            <div class="emission-line line-e"><span class="emission-value">
                                36 à 55
                            </span><span class="emission-letter letter-e">E</span></div>
                            <div class="emission-line line-f"><span class="emission-value">
                                56 à 80
                            </span><span class="emission-letter letter-f">F</span></div>
                            <div class="emission-line line-g"><span class="emission-value">
                                ≥ 80
                            </span><span class="emission-letter letter-g">G</span></div>
                        </div>
                        <div class="emission-scale">
                            <span class={`emission-number-container number${lettreGes}`}>
                                <span class="emission-number" aria-hidden="false">
                                    {valueGES}
                                    <abbr title="Quantity of greenhouse gas emissions" class="hidden">
                                        KgéqCO2 / m².an
                                    </abbr>
                                </span>
                            </span>
                        </div>
                        <span>
                            Forte émission de GES
                        </span>
                    </div>
                    <div class="ges-blank">
                        <span class="ges-blank-title">
                            GES vierge
                        </span>
                        <span class="ges-blank-legend">
                            Consommation non utilisable
                        </span>
                    </div>
                </figure>
            </div>
        </div>
    );
}

export default GasFile;