import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Select from 'react-select';
import Modal from './Modal';

const SearchForm = () => {
	const history = useHistory();
	const [modalOpen, setModalOpen] = useState(false)
	const [typesDeBiensUtilises, setTypesDeBiensUtilises] = useState([]);
	const [villesEtCodesPostauxUtilises, setVillesEtCodesPostauxUtilises] = useState([]);
	const [searchCriteria, setSearchCriteria] = useState({
		typeBien: '',
		surfaceMin: '',
		prixMax: '',
		villeCodePostal: '',
		reference: '',
		prixMin: '',
		pieceMin: '',
		pieceMax: '',
		surfaceMax: '',
		terrainMin: '',
		terrainMax: '',
		chambresMin: '',
		chambresMax: '',
	});
	let publicUrl = process.env.PUBLIC_URL + '/'
	let imagealt = 'image'
	const typesBiens = [
		'AGRICOLE_VITICOLE',
		'APPARTEMENT',
		'CAVE',
		'DEMEURE',
		'FOND_COMMERCE',
		'FORET',
		'GRANGE',
		'IMMEUBLE',
		'LOCAL_PROFESSIONNEL',
		'MAISON',
		'MARINA',
		'PARKING',
		'PROGRAMME_NEUF',
		'TERRAIN'
	];

	const toggleModal = () => {
		console.log("Modal")
		setModalOpen(!modalOpen)
	}

	useEffect(() => {
		console.log("searchCriteria", searchCriteria)
	}, [searchCriteria])

	const handleFormSubmit = (e) => {
		e.preventDefault();
		handleSearch(searchCriteria);
	};

	const handleSearch = (criteria) => {
		history.push({
			pathname: '/recherche',
			state: { searchCriteria: searchCriteria }
		});
	};

	const handleChange = (e) => {
		// if (e && e.target.value !== undefined) {
		// 	console.log('1er')
		// 	setSearchCriteria(prev => ({ ...prev, [e.target.name]: e.target.value }));
		// } else if (e.target.value) {
		// 	console.log('2eme')
		// 	setSearchCriteria(prev => ({ ...prev, [e.target.name]: e.target.value }));
		// }
		if (e && e.value !== undefined) {
			// console.log('1er', e.value)
			setSearchCriteria(prev => ({ ...prev, villeCodePostal: e.value }));
		} else if (e.target && e.target.value) {
			const named = e.target.name;
			const newVal = e.target.value;
			// console.log('2eme', e.target)
			setSearchCriteria(prev => ({ ...prev, [named]: newVal }));
		}
	};

	const formatOptionText = (type) => {
		return `${type.replace(/_/g, ' ')}`;
	}

	useEffect(() => {
		const biensEnregistrés = localStorage.getItem('biensData');
		if (biensEnregistrés) {
			const biens = JSON.parse(biensEnregistrés);
			const typesUtilises = new Set();
			const villesEtCodesPostauxUtilises = new Set();

			biens.forEach(bien => {
				if (bien.typeBien && typesBiens.includes(bien.typeBien)) {
					typesUtilises.add(bien.typeBien);
				}
				if (bien.codePostal && bien.ville && bien.codePostal.length > 0) {
					villesEtCodesPostauxUtilises.add(`${bien.codePostal} - ${bien.ville}`);
				}
			});

			setTypesDeBiensUtilises(Array.from(typesUtilises));
			setVillesEtCodesPostauxUtilises(Array.from(villesEtCodesPostauxUtilises));
		}
	}, []);

	const options = villesEtCodesPostauxUtilises.map(villeCodePostal => ({
		value: villeCodePostal,
		label: villeCodePostal
	}));

	return (
		<div className="ltn__car-dealer-form-area mt--250 mt-120 pb-115---">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__car-dealer-form-tab">
							<div className="ltn__tab-menu  text-uppercase d-none">
								<div className="nav">
									<a className="active show" data-bs-toggle="tab" href="#ltn__form_tab_1_1"><i className="fas fa-car" />Find A Car</a>
									<a data-bs-toggle="tab" href="#ltn__form_tab_1_2" ><i className="far fa-user" />Get a Dealer</a>
								</div>
							</div>
							<div className="tab-content bg-white box-shadow-1 position-relative">
								<div className="tab-pane fade active show" id="ltn__form_tab_1_1">
									<div className="car-dealer-form-inner">
										<form action="#" className="ltn__car-dealer-form-box row search-form" onSubmit={handleFormSubmit}>
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- col-lg-3 col-md-6">
												<select id="typeBien" className='nice-select' name="typeBien" onChange={handleChange} value={searchCriteria.typeBien} style={{ display: "block !important" }}>
													<option value=''>Sélectionnez un type</option>
													{typesDeBiensUtilises.map(type => (
														<option key={type} value={type}>{formatOptionText(type)}</option>
													))}
												</select>
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- col-lg-2 col-md-6">
												<input type='number' placeholder='Surface min' onChange={handleChange} id="surfaceMin" name="surfaceMin" style={{ paddingRight: 10 }} />
											</div>
											<div className='ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- col-lg-2 col-md-6'>
												<input type='number' placeholder='Prix max' onChange={handleChange} id="prixMax" name="prixMax" style={{ paddingRight: 10 }} />
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-meter---- col-lg-3 col-md-6 content-test1">
												<Select
													value={options.find(option => option.value === searchCriteria.villeCodePostal)}
													onChange={handleChange}
													options={options}
													placeholder="Ville/Code postal"
													className='test1 nice-select'
													noOptionsMessage={() => 'Aucune ville trouvée'}
												/>
											</div>
											<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-2 col-md-6">
												<div className="btn-wrapper text-center mt-0 go-top">
													{/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
													<button className="btn theme-btn-1 btn-effect-1 text-uppercase">Rechercher</button>
												</div>
											</div>
											<span className="btn text-uppercase" style={{ padding: 0, margin: "0 auto", fontWeight: 600, textDecoration: "underline", width: "auto" }} onClick={toggleModal}>Recherche avancée</span>
										</form>
										{modalOpen && <Modal searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} closeModal={toggleModal} onSearch={handleSearch} />}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SearchForm