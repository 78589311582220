import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';

const PolitiqueConfidentialite = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant' // ou 'instant', mais 'auto' est généralement le comportement par défaut
        });
    }, []);

    return <div>
        <TopBar />
        <Navbar />
        <div className="container" style={{ padding: "50px 20px" }}>
            <h1>Politique de confidentialité</h1>
            <p style={{ color: "#333" }}>Chez NeoImo, nous accordons une grande importance à la confidentialité et à la sécurité des informations personnelles de nos utilisateurs. Cette politique de confidentialité explique comment nous collectons, utilisons, divulguons et protégeons les informations personnelles que vous nous fournissez lorsque vous visitez notre site Web www.neoimo.com ou utilisez nos services. Veuillez lire attentivement cette politique pour comprendre comment nous traitons vos informations personnelles.</p>

            <h2>Collecte d'informations personnelles</h2>
            <p style={{ color: "#333" }}>Lorsque vous visitez notre site Web ou utilisez nos services, nous pouvons collecter certaines informations personnelles vous concernant. Les informations que nous collectons peuvent inclure votre nom, votre adresse e-mail, votre numéro de téléphone, votre adresse postale, ainsi que d’autres informations nécessaires pour vous fournir nos services immobiliers. Nous collectons ces informations de différentes manières, notamment lorsque vous les fournissez volontairement, lorsque vous vous inscrivez sur notre site Web, lorsque vous communiquez avec nous ou lorsque vous utilisez nos services.</p>
            <h2>Utilisation des informations personnelles</h2>
            <p style={{ color: "#333" }}>Nous utilisons les informations personnelles que nous collectons pour vous fournir nos services immobiliers et pour améliorer votre expérience utilisateur. Ces utilisations peuvent inclure la recherche de biens immobiliers correspondant à vos critères, la communication avec vous concernant les propriétés et les transactions immobilières, la personnalisation de notre site Web en fonction de vos préférences, l’analyse de données et de tendances pour améliorer nos services, ainsi que la prévention des activités frauduleuses et illégales.</p>
            <h2>Divulgation des informations personnelles</h2>
            <p style={{ color: "#333" }}>Nous ne vendons ni ne louons vos informations personnelles à des tiers à des fins commerciales. Cependant, dans le cadre de nos activités commerciales normales, nous pouvons divulguer vos informations personnelles à des tiers dans les situations suivantes :</p>
            <ul>
                <li>Lorsque cela est nécessaire pour fournir nos services immobiliers, tels que la communication avec des vendeurs, des acheteurs ou des locataires potentiels.</li>
                <li>Lorsque nous sommes légalement tenus de le faire, par exemple pour répondre à une demande des autorités judiciaires ou réglementaires.</li>
            </ul>
            <h2>Sécurité des informations personnelles</h2>
            <p style={{ color: "#333" }}>Nous mettons en place des mesures de sécurité appropriées pour protéger vos informations personnelles contre tout accès non autorisé, toute divulgation, toute altération ou toute destruction. Cependant, veuillez noter qu’aucune méthode de transmission sur Internet ou de stockage électronique n’est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue de vos informations.</p>
            <h2>Cookies et technologies similaires</h2>
            <p style={{ color: "#333" }}>Nous utilisons des cookies et d’autres technologies similaires pour collecter des informations et améliorer votre expérience sur notre site Web. Les cookies sont de petits fichiers texte stockés sur votre appareil lorsque vous visitez notre site. Vous pouvez configurer votre navigateur pour refuser tous les cookies ou pour vous alerter lorsque des cookies sont envoyés. Veuillez noter que certaines fonctionnalités de notre site Web peuvent ne pas fonctionner correctement si vous désactivez les cookies.</p>
            <h2>Modifications de la politique de confidentialité</h2>
            <p style={{ color: "#333" }}>Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Nous vous encourageons à consulter régulièrement cette page pour prendre connaissance des éventuelles modifications. Les modifications apportées à cette politique de confidentialité entreront en vigueur dès leur publication sur cette page.</p>
            <h2>Nous contacter</h2>
            <p style={{ color: "#333" }}>Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter à l’adresse suivante : info@neoimo.com</p>
        </div>
        <Footer />
    </div>
}

export default PolitiqueConfidentialite

