import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-grid-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import ShopGridSearch from './shop-components/shop-grid-search';
import { Link, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import Product_Details from './product-details';
import DpeFile from './DpeFile';
import GasFile from './GasFile';

const SaleProperty = () => {
    const { id } = useParams();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [property, setProperty] = useState([]);
    let publicUrl = process.env.PUBLIC_URL + '/'
    const images = property.images || [];

    const currentImg = images[currentIndex] || "";

    const [otherProperties, setOtherProperties] = useState([]);

    useEffect(() => {
        // Fonction pour récupérer les détails du bien
        const fetchPropertyDetails = () => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant' // ou 'instant', mais 'auto' est généralement le comportement par défaut
            });
            // Récupération des biens depuis le localStorage
            const savedBiens = localStorage.getItem('biensData');
            const biens = savedBiens ? JSON.parse(savedBiens) : [];

            // Recherche du bien correspondant à l'ID
            const foundProperty = biens.find(bien => bien.affId === id);
            console.log("foundProperty", foundProperty)

            // Mise à jour de l'état avec les détails du bien trouvé
            if (foundProperty) {
                setProperty(foundProperty);

                const otherProperties = biens.filter(bien =>
                    bien.affId !== id && bien.type === foundProperty.type);


                console.log(otherProperties)
                setOtherProperties(otherProperties);
            } else {
                // Gestion de l'absence du bien (facultatif)
                console.log('Bien non trouvé');
            }
        };

        fetchPropertyDetails();
    }, [id]);

    const switchImg = (newIndex) => {
        setCurrentIndex(newIndex);
    };

    const prevImg = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const nextImg = () => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    return <div>
        <TopBar />
        <Navbar />
        <div className="ltn__shop-details-area pb-10">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                            <div className='row mb-30'>
                                <div className='col-lg-8'>
                                    <h1 style={{ marginBottom: 0 }}>{property.intitule}</h1>
                                    <div className="ltn__blog-meta" style={{ margin: "10px 0px" }}>
                                        <ul>
                                            {property.nbrePieces && (
                                                <li className="ltn__blog-date">
                                                    <h3 style={{ margin: 0 }}>{property.nbrePieces} pièces</h3>
                                                </li>
                                            )}
                                            {property.surfaceHabitable && (
                                                <li className="ltn__blog-author">
                                                    <h3 style={{ margin: 0 }}>{property.surfaceHabitable} m<sup>2</sup></h3>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    {property.codePostal && property.ville && (
                                        <h5 style={{ margin: 0, fontWeight: 500 }}><span className="ltn__secondary-color"><i className="flaticon-pin" style={{ color: "#AC2E31" }} /></span>{property.codePostal} {property.ville}</h5>
                                    )}
                                </div>
                                <div className='col-lg-4'>
                                    {property.type === 'LOCATION' ? (
                                        <div>
                                            <h1 style={{ textAlign: "end", marginBottom: 0 }}>{parseInt(property.prixLoyer) + parseInt(property.charges)} €</h1>
                                            <h5 style={{ textAlign: "end", margin: 0, fontWeight: 500, color: "#AC2E31", fontSize: 12 }}>par mois charges comprises</h5>
                                        </div>
                                    ) : (
                                        <h1 style={{ textAlign: "end", marginBottom: 0 }}>{property.prixLoyer} €</h1>
                                    )}
                                </div>
                            </div>

                            {currentImg && (
                                <div className="selector">
                                    <div className="primary-img">
                                        <img src={currentImg} />

                                        <div className="controls">
                                            <button className="controls-prev" type="button" onClick={prevImg}>
                                                <i className="fa-solid fa-arrow-up"></i>
                                            </button>
                                            <button className="controls-next" type="button" onClick={nextImg}>
                                                <i className="fa-solid fa-arrow-up"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <ul className="selector-images">
                                        {images.map((image, index) => (
                                            <li key={index}>
                                                <button onClick={() => switchImg(index)}>
                                                    <img src={image} width="200" height="200" />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/* 2 buttons full width */}
                            <div className="ltn__shop-details-tab-menu mt-30 mb-40" style={{ display: "flex", gap: 10 }}>
                                <Link to='/contact' className="btn btn-primary" style={{ backgroundColor: "#AC2E31", borderColor: "#AC2E31", borderRadius: 16, padding: "20px 25px", marginRight: 0, width: "50%" }}>Envoyer un message</Link>
                                <a href='tel:0329941800' className="btn btn-primary" style={{ backgroundColor: "inherit", borderColor: "#AC2E31", borderRadius: 16, padding: "20px 25px", width: "50%", color: "#AC2E31" }}>Téléphoner à l'agence</a>
                            </div>

                            <h3 className="title-2">Aperçu</h3>
                            <div className="container">
                                <div className="row" id='services'>
                                    {property.nbrePieces && (
                                        <div className="col-lg-4 col-sm-6 col-12" style={{ paddingLeft: 0 }}>
                                            <div className="ltn__feature-item box-shadow-1" style={{ backgroundColor: "#AC2E31", borderRadius: 16, padding: "20px 20px" }}>
                                                <div className="ltn__feature-info">
                                                    <h4 style={{ color: "#fff", margin: "10px 0px 20px 0px", fontWeight: 500 }}><Link to="#">Nb de pièces</Link></h4>
                                                    <h5 style={{ margin: 0, color: "#FFF" }}><i className="fa fa-layer-group"></i> {property.nbrePieces} pièces</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {property.nbreChambres && (
                                        <div className="col-lg-4 col-sm-6 col-12">
                                            <div className="ltn__feature-item box-shadow-1" style={{ backgroundColor: "#54595F", borderRadius: 16, padding: "20px 20px" }}>
                                                <div className="ltn__feature-info" style={{ textAlign: "justify" }}>
                                                    <h4 style={{ color: "#fff", margin: "10px 0px 20px 0px", fontWeight: 500 }}><Link to="#">Nb de chambres</Link></h4>
                                                    <h5 style={{ margin: 0, color: "#FFF" }}><i className="fa fa-bed"></i> {property.nbreChambres} chambres</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {property.surfaceHabitable && (
                                        <div className="col-lg-4 col-sm-6 col-12" style={{ paddingRight: 0 }}>
                                            <div className="ltn__feature-item box-shadow-1" style={{ backgroundColor: "#000", borderRadius: 16, padding: "20px 20px" }}>
                                                <div className="ltn__feature-info" style={{ textAlign: "justify" }}>
                                                    <h4 style={{ color: "#fff", margin: "10px 0px 20px 0px", fontWeight: 500 }}><Link to="#">Surface habitable</Link></h4>
                                                    <h5 style={{ margin: 0, color: "#FFF" }}><i className="fa fa-expand"></i> {property.surfaceHabitable} m<sup>2</sup></h5>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <h3 className="title-2">Description</h3>
                            {property.commentaire && (
                                <p className='product-title' style={{ color: "#000" }}>{property.commentaire}</p>
                            )}
                            <h4 className="title-2">Informations détaillées</h4>
                            <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                                <ul className='col-6'>
                                    {property.reference && (
                                        <li><label>Référence du bien :</label> <span>{property.reference}</span></li>
                                    )}
                                    {property.typeBien && (
                                        <li><label>Type de bien :</label> <span>{property.typeBien}</span></li>
                                    )}
                                    {property.codePostal && property.ville && (
                                        <li><label>Localisation :</label> <span>{property.codePostal} {property.ville}</span></li>
                                    )}
                                    {property.nbrePieces && (
                                        <li><label>Nb de pièce :</label> <span>{property.nbrePieces} pièces</span></li>
                                    )}
                                </ul>
                                <ul className='col-6'>
                                    {property.nbreChambres && (
                                        <li><label>Nb de chambres :</label> <span>{property.nbreChambres} chambres</span></li>
                                    )}
                                    {property.surfaceHabitable && (
                                        <li><label>Surface habitable :</label> <span>{property.surfaceHabitable} m<sup>2</sup></span></li>
                                    )}
                                    {property.modeChauffage && (
                                        <li><label>Mode de chauffage :</label> <span>{property.modeChauffage}</span></li>
                                    )}
                                    {/* <li><label>Price:</label> <span>2</span></li> */}
                                </ul>
                            </div>
                            <h4 className="title-2">Informations détaillées</h4>
                            <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                                <ul className='col-6'>
                                    {property.prixLoyer && property.type === "LOCATION" ? (
                                        <li><label>Prix :</label> <span>{parseInt(property.prixLoyer) + parseInt(property.charges)} €</span></li>
                                    ) : (
                                        <li><label>Prix :</label> <span>{property.prixLoyer} €</span></li>
                                    )}
                                    <li><label>Honoraires : </label> <span>Charge vendeur inclus</span></li>
                                </ul>
                                <ul className='col-6'>
                                    {property.numMandat && (
                                        <li><label>Numéro mandat :</label> <span>{property.numMandat}</span></li>
                                    )}
                                    {property.typeMandat && (
                                        <li><label>Type de mandat :</label> <span>{property.typeMandat}</span></li>
                                    )}
                                </ul>
                            </div>
                            <h4 className="title-2">Informations énergétiques</h4>
                            <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                                <ul style={{ width: "100%" }}>
                                    {property.consommationEnergie && (
                                        <li style={{ width: "100%" }}><label style={{ width: "50%" }}>Classe énergétique :</label> <span>{property.consommationEnergie}</span></li>
                                    )}
                                    {property.gazEffetSerre && (
                                        <li style={{ width: "100%" }}><label style={{ width: "50%" }}>Diagnostic de gaz à effets de serre : </label> <span>{property.gazEffetSerre}</span></li>
                                    )}
                                    {property.consoAnnuelleEnergie && (
                                        <li style={{ width: "100%" }}><label style={{ width: "50%" }}>Valeur consomation annuelle énergie : </label> <span>{property.consoAnnuelleEnergie} kWh/m².an</span></li>
                                    )}
                                    {property.valeurGes && (
                                        <li style={{ width: "100%" }}><label style={{ width: "50%" }}>Valeur gaz à effets de serre :</label> <span>{property.valeurGes} CO2/m².an</span></li>
                                    )}
                                </ul>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <DpeFile
                                        valueDPE={property.consoAnnuelleEnergie}
                                        displayDPE={true}
                                        displayInline={false}
                                        shortcode={false}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <GasFile
                                        valueGES={property.valeurGes}
                                        displayDPE={true}
                                        displayInline={false}
                                        shortcode={false}
                                    />
                                </div>
                            </div>
                            <h6 style={{ fontWeight: 400, margin: 0 }}>Les coûts sont estimés en fonction des caractéristiques de votre logement et pour une utilisation standard. Montant estimé des dépenses annuelles d’énergie pour un usage standard : entre {property.chargeEnergieMin} € et {property.chargeEnergieMax} € par an. Prix moyens des énergies indexés au 01/01/2021 (abonnement compris).</h6>
                            <h4 className="title-2">Nos biens en ventes</h4>
                            <div className="row ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
                                {otherProperties.map((bien, index) => (
                                    <div className="col-lg-4" key={index}>
                                        <div className="ltn__blog-item ltn__blog-item-3">
                                            <div className="ltn__blog-img" style={{ margin: 0 }}>
                                                <Link to={`/biens/${bien.affId}`}>
                                                    <img src={bien.premiereImage} alt={bien.intitule} style={{ height: "280px", width: "100%", objectFit: "cover" }} />
                                                </Link>
                                            </div>
                                            <div className="ltn__blog-brief" style={{ padding: "1px 30px 20px 30px" }}>
                                                <div className="ltn__blog-meta">
                                                    <h4 style={{ height: "60px", fontWeight: 500 }}>{bien.intitule}</h4>
                                                </div>
                                                <div className="ltn__blog-meta">
                                                    {bien.typeBien === 'LOYER' ? (
                                                        <h4>LOYER: {bien.prixLoyer} €</h4>
                                                    ) : (
                                                        <h4>PRIX: {bien.prixLoyer} €</h4>
                                                    )}
                                                </div>
                                                <div className="ltn__blog-meta">
                                                    <span><i className="fas fa-map-marker-alt"></i> {bien.codePostal} - {bien.ville}</span>
                                                </div>
                                                <div className="ltn__blog-meta">
                                                    <ul>
                                                        {bien.nbrePieces && (
                                                            <li className="ltn__blog-author">
                                                                <i className="fa fa-layer-group"></i> {bien.nbrePieces}
                                                            </li>
                                                        )}
                                                        {bien.nbreChambres && (
                                                            <li className="ltn__blog-tags">
                                                                <i className="fa fa-bed"></i> {bien.nbreChambres}
                                                            </li>
                                                        )}
                                                        {bien.surfaceHabitable && (
                                                            <li className="ltn__blog-tags">
                                                                <i className='fas fa-expand'></i> {bien.surfaceHabitable} m²
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="ltn__blog-meta-btn">
                                                    <div className="ltn__blog-btn">
                                                        <Link to={`/biens/${bien.affId}`}>Voir le détail du bien</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    // <div className="col-xl-4 col-sm-6 col-12 go-top">
                                    //     <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                    //         <div className="product-img">
                                    //             <Link to="/shop"><img src={publicUrl + "assets/img/product-3/1.jpg"} alt="#" /></Link>
                                    //             <div className="real-estate-agent">
                                    //                 <div className="agent-img">
                                    //                     <Link to="/team-details"><img src={publicUrl + "assets/img/blog/author.jpg"} alt="#" /></Link>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //         <div className="product-info">
                                    //             <div className="product-badge">
                                    //                 <ul>
                                    //                     <li className="sale-badg">For Rent</li>
                                    //                 </ul>
                                    //             </div>
                                    //             <h2 className="product-title"><Link to="/shop">New Apartment Nice View</Link></h2>
                                    //             <div className="product-img-location">
                                    //                 <ul>
                                    //                     <li>
                                    //                         <Link to="/shop"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
                                    //                     </li>
                                    //                 </ul>
                                    //             </div>
                                    //             <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                    //                 <li><span>3 </span>
                                    //                     Bedrooms
                                    //                 </li>
                                    //                 <li><span>2 </span>
                                    //                     Bathrooms
                                    //                 </li>
                                    //                 <li><span>3450 </span>
                                    //                     square Ft
                                    //                 </li>
                                    //             </ul>
                                    //             <div className="product-hover-action">
                                    //                 <ul>
                                    //                     <li>
                                    //                         <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
                                    //                             <i className="flaticon-expand" />
                                    //                         </a>
                                    //                     </li>
                                    //                     <li>
                                    //                         <a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
                                    //                             <i className="flaticon-heart-1" /></a>
                                    //                     </li>
                                    //                     <li>
                                    //                         <Link to="/shop" title="Compare">
                                    //                             <i className="flaticon-add" />
                                    //                         </Link>
                                    //                     </li>
                                    //                 </ul>
                                    //             </div>
                                    //         </div>
                                    //         <div className="product-info-bottom">
                                    //             <div className="product-price">
                                    //                 <span>$349,00<label>/Month</label></span>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <PageHeader headertitle="Nos biens disponibles à la recherche :" /> */}
        {/* <ShopGridSearch /> */}
        <Footer />
    </div>
}

export default SaleProperty

