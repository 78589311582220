import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const AboutHistoire = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';
	const location = useLocation();

	useEffect(() => {
		// Si l'URL contient un hash
		if (location.hash) {
			const id = location.hash.replace('#', '');
			const element = document.getElementById(id);
			if (element) {
				// Faire défiler jusqu'à l'élément
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}
		// Se déclenche à chaque changement de l'objet location
	}, [location]);

	return (
		<div className="ltn__about-us-area pt-60 pb-90 go-top" id='histoire'>
			<div className="container">
				<div className="section-title-area ltn__section-title-2--- text-center" style={{ marginBottom: 100 }} >
					<img src={publicUrl + "assets/img/banner/histoire-neoimo.png"} alt="#" className='' />
				</div>
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<p>Implantée au cœur de la ville depuis 1998, l’agence Neo Immobilier devient NEO IMO.</p>
								<p>Forte de son ancienneté d’implantation et de son authenticité, l’unique agence immobilière d’origine locale évolue. Reprise depuis le 1er janvier 2023 par le néocastrien Luca Biblot, composée d’une équipe expérimentée sur le marché local, elle bénéficie d’une nouvelle dynamique commerciale tout en conservant les valeurs humaines, familiales et les relations de confiance qui ont fait sa notoriété et lui ont permis de s’installer durablement dans le secteur.</p>
								<p>Spécialisée dans la transaction immobilière, membre du groupement d’agences IMOGROUP, l’agence NEO IMO vous guide avec professionnalisme dans votre quête d’un bien à vendre ou à acheter. L’agence dispose également d’un service logements neufs et propose l’accès à des programmes neufs disponibles partout en France.</p>
								<p>Vous ne parvenez pas à effectuer un choix parmi les nombreuses agences des réseaux immobiliers implantées à Neufchâteau ? Faites le choix du local en contactant l’équipe de NÉO IMO et en venant rencontrer ses membres à l’agence du 48 rue Saint-Jean !</p>
							</div>
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl + "assets/img/inauguration-neoimo.png"} alt="About Us Image" style={{ height: "500px", width: "100%", objectFit: "cover" }} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutHistoire