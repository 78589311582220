import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV5 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pb-115 go-top mt-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-5 align-self-center">
						<div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
							<img src={publicUrl + "assets/img/slider/neoimo-slider.jpeg"} alt="Image" style={{ height: "450px", width: "auto", objectFit: "cover", borderRadius: 16 }} />
						</div>
					</div>
					<div className="col-lg-7 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h1 className="">Pour Vous Accompagner Au Mieux Dans Votre Projet Immobilier, Voici Les Étapes Que Nous Vous Proposons Pour La Vente Ou L'acquisition De Votre Bien.</h1>
							</div>
							<div className="btn-wrapper animated">
								<Link to="#services" className="theme-btn-1 btn btn-effect-1 text-uppercase">En savoir plus</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default AboutV5