import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-grid-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';
import ShopGridLouer from './shop-components/shop-grid-louer';

const ShopLouer = () => {

    return <div>
        <TopBar />
        <Navbar />
        <PageHeader headertitle="Nos biens disponibles à la location :" />
        <ShopGridLouer />
        <Footer />
    </div>
}

export default ShopLouer

