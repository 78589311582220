import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

let publicUrl = process.env.PUBLIC_URL + '/'

const columnsData = [
	{ title: 'ACHETER', description: 'Trouvez la maison de vos rêves en un clic ! Découvrez notre catalogue de biens à vendre et laissez-nous vous accompagner dans votre projet d’achat immobilier.', image: publicUrl + "assets/img/acheter.jpg", link: "acheter" },
	{ title: 'LOUER', description: 'Louez en toute sérénité avec notre agence immobilière. Découvrez notre sélection de biens à louer et profitez de notre expertise pour trouver le logement qui vous convient parfaitement.', image: publicUrl + "assets/img/location.png", link: "louer" },
	{ title: 'ESTIMER', description: 'Estimez la valeur de votre bien immobilier en toute confiance avec notre agence. Notre expertise du marché local nous permettra de vous donner une estimation juste et précise de votre propriété.', image: publicUrl + "assets/img/louer.jpg", link: "vendre" },
	{ title: 'LOGEMENTS NEUFS', description: 'Découvrez notre sélection de logements neufs et profitez de notre expertise pour concrétiser votre projet immobilier. Nous avons ce qu’il vous faut pour investir dans un bien immobilier neuf qui répondra à toutes vos attentes.', image: publicUrl + "assets/img/logements-neufs.jpeg", link: "logements-neufs" },
];

const ServiceV2 = () => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	const [backgroundImage, setBackgroundImage] = useState(publicUrl + "assets/img/acheter.jpg"); // Mettez l'image de fond par défaut
	const [visibleDescription, setVisibleDescription] = useState(null);

	const handleMouseEnter = (image, index) => {
		setBackgroundImage(image); // Change l'image de fond
		setVisibleDescription(index); // Montre la description de la colonne survolée
	};

	const handleMouseLeave = () => {
		setVisibleDescription(null); // Cache toutes les descriptions
	};

	return (
		<div className="ltn__feature-area pt-90 pb-90 go-top">
			<div className="">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center pt-90">
							{/* <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">features</h6> */}
							<img src={publicUrl + "assets/img/banner/services-neoimo.png"} alt="#" className='mt-60' />
						</div>
					</div>
				</div>
				<div className='parent-content'>
					{/* <img src="https://neoimo.com/wp-content/uploads/elementor/thumbs/service-2-q7bdqbr4ghn94kbguf6wnjf37f07gn4g3zwu9atlhc.png" alt="background" className="background" /> */}
					<div className="section" style={{ backgroundImage: `url(${backgroundImage})` }}>
						<div className="overlay"></div>
						<div className="columns">
							{columnsData.map((column, index) => (
								<div
									key={column.title}
									className="column"
									onMouseEnter={() => handleMouseEnter(column.image, index)}
									onMouseLeave={handleMouseLeave}
								>
									<Link to={`/${column.link}`} className="link-wrapper" style={{ zIndex: "99" }}>
										<h2>{column.title}</h2>
									</Link>
									<p className={`description ${visibleDescription === index ? 'visible' : ''}`} style={{ fontSize: "16px" }}>
										{column.description}
									</p>
									<Link to={`/${column.link}`} className="link-wrapper" style={{ zIndex: "99" }}>
										<button className='button-wrapper'><i class="fa-solid fa-arrow-right"></i> </button>
									</Link>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

// class ServiceV2 extends Component {


// 	render() {

// 		let publicUrl = process.env.PUBLIC_URL + '/'

// 		return <div className="ltn__feature-area pt-90 pb-90 go-top">
// 			<div className="container">
// 				<div className="row">
// 					<div className="col-lg-12">
// 						<div className="section-title-area ltn__section-title-2--- text-center">
// 							{/* <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">features</h6> */}
// 							<h1 className="section-title">Core Features</h1>
// 						</div>
// 					</div>
// 				</div>
// 				<div className="row ltn__custom-gutter">
// 					<div className="col-lg-3 col-sm-6 col-12">
// 						<div className="ltn__feature-item ltn__feature-item-6">
// 							<div className="ltn__feature-icon">
// 								<span><i className="flaticon-apartment" /></span>
// 							</div>
// 							<div className="ltn__feature-info">
// 								<h4><Link to="/service-details">Acheter</Link></h4>
// 								<p>Trouvez la maison de vos rêves en un clic ! Découvrez notre catalogue de biens à vendre et laissez-nous vous accompagner dans votre projet d’achat immobilier.</p>
// 							</div>
// 						</div>
// 					</div>
// 					<div className="col-lg-3 col-sm-6 col-12">
// 						<div className="ltn__feature-item ltn__feature-item-6 active">
// 							<div className="ltn__feature-icon">
// 								<span><i className="flaticon-park" /></span>
// 							</div>
// 							<div className="ltn__feature-info">
// 								<h4><Link to="/service-details">Louer</Link></h4>
// 								<p>Louez en toute sérénité avec notre agence immobilière. Découvrez notre sélection de biens à louer et profitez de notre expertise pour trouver le logement qui vous convient parfaitement.</p>
// 							</div>
// 						</div>
// 					</div>
// 					<div className="col-lg-3 col-sm-6 col-12">
// 						<div className="ltn__feature-item ltn__feature-item-6">
// 							<div className="ltn__feature-icon">
// 								<span><i className="flaticon-maps-and-location" /></span>
// 							</div>
// 							<div className="ltn__feature-info">
// 								<h4><Link to="/service-details">Estimer</Link></h4>
// 								<p>Estimez la valeur de votre bien immobilier en toute confiance avec notre agence. Notre expertise du marché local nous permettra de vous donner une estimation juste et précise de votre propriété.</p>
// 							</div>
// 						</div>
// 					</div>
// 					<div className="col-lg-3 col-sm-6 col-12">
// 						<div className="ltn__feature-item ltn__feature-item-6">
// 							<div className="ltn__feature-icon">
// 								<span><i className="flaticon-excavator" /></span>
// 							</div>
// 							<div className="ltn__feature-info">
// 								<h4><Link to="/service-details">Logements neufs</Link></h4>
// 								<p>Découvrez notre sélection de logements neufs et profitez de notre expertise pour concrétiser votre projet immobilier. Nous avons ce qu’il vous faut pour investir dans un bien immobilier neuf qui répondra à toutes vos attentes.</p>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>

// 	}
// }

export default ServiceV2