import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pt-120 pb-90 mt--30 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<div className='container'>
								<div className='row' style={{gap: "12px"}}>
									<div className="about-img col-6" style={{width: "45%", padding: 0}}>
										<div style={{backgroundColor: "#AC2E31", height: "230px", borderRadius: 16}}></div>
									</div>
									<div className="about-img col-6" style={{width: "45%", padding: 0}}>
									<div style={{backgroundColor: "#54595F", height: "230px", borderRadius: 16}}></div>
									</div>
									<div className="about-img col-6" style={{width: "45%", padding: 0}}>
									<div style={{backgroundColor: "black", height: "230px", borderRadius: 16}}></div>
									</div>
									<div className="about-img col-6" style={{width: "45%", padding: 0}}>
									<div style={{backgroundColor: "#AC2E31", height: "230px", borderRadius: 16}}></div>
									</div>
								</div>
							</div>
							{/* <img src={publicUrl + "assets/img/others/13.png"} alt="About Us Image" style={{height: "650px", width: "100%", objectFit: "cover"}}/> */}
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h1 className="section-title">Logements Neufs
									<span>.</span></h1>
								<p>Découvrez notre service logements neufs ! Résidence principale ? Investissement locatif ? Résidence secondaire ? Défiscalisation ? Bénéficiez de nos programmes immobiliers neufs au même prix que ceux proposés par les promoteurs, sans aucun frais d'agence supplémentaires.</p>
								<p>Nous avons accès à une vaste sélection de logements neufs sur toute la France !</p>
								<p>Notre équipe est là pour vous guider tout au long du processus d'achat. Nous vous accompagnons depuis la sélection du logement jusqu'à la signature du contrat, en vous fournissant des conseils personnalisés et en répondant à toutes vos questions.</p>
								<p>Contactez-nous pour découvrir nos programmes actuellement disponibles à la vente et que l’on puisse trouver ensemble le logement dont vous souhaitez ! Quelques exemples de programmes disponibles à la vente :</p>
							</div>

							<div className="btn-wrapper animated">
								<Link to="/contact" className="theme-btn-1 btn btn-effect-1">Contactez-nous</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default AboutV4