const DpeFile = ({ valueDPE, displayDPE, displayInline, shortcode }) => {
    let classDpeGes = 'wpdpeges';
    let classSC = '';
    let classBlank = '';

    if (displayInline) {
        classDpeGes += !shortcode ? ' dpe-ges-inline' : '';
        classSC += shortcode ? ' dpeges-inline' : '';
    }

    classBlank = !valueDPE ? ' dpe-blank-active' : '';

    const getLetterDpe = (value) => {
        if (value <= 50) return 'A';
        else if (value <= 90) return 'B';
        else if (value <= 150) return 'C';
        else if (value <= 230) return 'D';
        else if (value <= 330) return 'E';
        else if (value <= 450) return 'F';
        else return 'G';
    };

    const lettreDpe = getLetterDpe(valueDPE);

    return (
        <div className={classDpeGes}>
            <div className={`dpe ${classSC} ${classBlank}`}>
                <span className="title">Diagnostic de performance énergétique</span>
                <figure className="diagnostic diagnostic-dpe">
                    <figcaption className="diagnostic-title hidden">
                        Diagnostic de performance énergétique
                    </figcaption>
                    <div className="diagnostic-container" aria-hidden="true">
                        <div className="diagnostic-legend"><span>
                            Logement économe
                        </span> <span><abbr title="Primary Energy Unit">
                            KWhEP / m².an
                        </abbr></span></div>
                        <div className="diagnostic-diagram">
                            <div className="diagnostic-line line-a"><span className="diagnostic-value">
                                ≤ 50
                            </span><span className="diagnostic-letter letter-a">A</span></div>
                            <div className="diagnostic-line line-b"><span className="diagnostic-value">
                                51 à 90
                            </span><span className="diagnostic-letter letter-b">B</span></div>
                            <div className="diagnostic-line line-c"><span className="diagnostic-value">
                                91 à 150
                            </span><span className="diagnostic-letter letter-c">C</span></div>
                            <div className="diagnostic-line line-d"><span className="diagnostic-value">
                                151 à 230
                            </span><span className="diagnostic-letter letter-d">D</span></div>
                            <div className="diagnostic-line line-e"><span className="diagnostic-value">
                                231 à 330
                            </span><span className="diagnostic-letter letter-e">E</span></div>
                            <div className="diagnostic-line line-f"><span className="diagnostic-value">
                                331 à 450
                            </span><span className="diagnostic-letter letter-f">F</span></div>
                            <div className="diagnostic-line line-g"><span className="diagnostic-value">
                                ≥ 450
                            </span><span className="diagnostic-letter letter-g">G</span></div>
                        </div>
                        <div className="diagnostic-scale">
                            <span className={`diagnostic-number-container number${lettreDpe}`}>
                                <span className="diagnostic-number" aria-hidden="false">
                                    {valueDPE} <abbr className="hidden" title="Primary Energy Unit">
                                        KWhep / m².year
                                    </abbr>
                                </span>
                            </span>
                        </div>
                        <span>
                            Logement énergivore
                        </span>
                    </div>
                    <div className="dpe-blank">
                        <span className="dpe-blank-title">
                            DPE vierge
                        </span>
                        <span className="dpe-blank-legend">
                            Consommation non utilisable
                        </span>
                    </div>
                </figure>
            </div>
        </div>
    );
}

export default DpeFile;