import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="google-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2651.2914480833324!2d5.6933057!3d48.3549231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ecb341a60e4f79%3A0x6fbeca138c9f1ee8!2sNEO%20IMO!5e0!3m2!1sfr!2sus!4v1705328509356!5m2!1sfr!2sus" width="100%" height="50%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} ></iframe>
        </div>
    }
}

export default Map