import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Social extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/NEOIMONEUFCHATEAU/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" style={{ background: "white", borderRadius: 99, padding: "4px 8px", color: "#AC2E31", marginRight: "5px" }} />Suivez-nous sur Facebook !</a></li>
			</ul>
		</div>
	}
}

export default Social