import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import HistoryV1 from './section-components/history';
import WhyChooseUs from './section-components/why-choose-us';
import CallToActonV4 from './section-components/call-to-action-v4';
// import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';

const History = () => {
    return <div>
        <TopBar /> 
        <Navbar /> 
        <HistoryV1 customClass="pt-20" />
        <Footer />
    </div>
}

export default History

