import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const AboutV1 = () => {
	let publicUrl = process.env.PUBLIC_URL + '/'

	useEffect(() => {
		if (!document.getElementById('facebook-jssdk')) {
			const script = document.createElement('script');
			script.id = 'facebook-jssdk';
			script.src = "//connect.facebook.net/en_US/all.js#xfbml=1";
			document.body.appendChild(script);
		}
	}, [])

	return (
		<div className="ltn__about-us-area pt-120 pb-90 ">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<div id="fb-root">
								<div className="fb-like-box"
									data-href="https://www.facebook.com/NEOIMONEUFCHATEAU/"
									data-width="500"
									data-height="700"
									data-show-faces="false"
									data-header="true"
									data-stream="true"
									data-show-border="true">
								</div>
							</div>
							{/* <img src={publicUrl + "assets/img/others/7.png"} alt="About Us Image" /> */}
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h1 className="section-title" style={{ textTransform: 'uppercase' }}>NEOIMO vous accompagne
									à chaque étape de votre projet<span>.</span></h1>
								<p>Depuis 1998, Neo Immobilier a établi sa présence au centre-ville, et maintenant, sous le nom de NEO IMO, elle poursuit son engagement envers ses clients en offrant des services immobiliers de qualités.</p>
							</div>
							{/* 2 columns responsive */}
							<div className="about-us-info-wrap-inner">
								<div className="row">
									<div className="col-lg-6 col-md-6">
										<div className="about-us-info-item">
											<div className="ltn__gallery-item-img">
												{/* <video controls width="250">
													<source src={publicUrl + "assets/media/neoimo.mp4"} type="video/mp4" />
												</video> */}
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="about-us-info-item">
											<h4>Un projet ?</h4>
											<p>Un projet immobilier en cours ou à venir sur le secteur de Neufchâteau et ses alentours ? Echangeons dès maintenant à ce sujet !</p>
										</div>
									</div>
								</div>
							</div>
							{/* <ul className="ltn__list-item-half clearfix">
								<li>
									<i className="flaticon-home-2" />
									Smart Home Design
								</li>
								<li>
									<i className="flaticon-mountain" />
									Beautiful Scene Around
								</li>
								<li>
									<i className="flaticon-heart" />
									Exceptional Lifestyle
								</li>
								<li>
									<i className="flaticon-secure" />
									Complete 24/7 Security
								</li>
							</ul> */}
							<div className="btn-wrapper animated go-top">
								<Link to="/contact" className="theme-btn-1 btn btn-effect-1">Contactez-nous</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutV1