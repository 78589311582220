import React from 'react';
import Navbar from './global-components/navbar-v2';
import AboutV4 from './section-components/about-v4';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';

const About_v1 = () => {
    return <div>
        <TopBar />
        <Navbar />
        <AboutV4/>
        <Footer />
    </div>
}

export default About_v1

