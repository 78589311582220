import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import TopBar from './global-components/topbar';

const MentionLegal = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant' // ou 'instant', mais 'auto' est généralement le comportement par défaut
        });
    }, []);

    return <div>
        <TopBar />
        <Navbar />
        <div className="container" style={{ padding: "50px 20px" }}>
            <h1>Mentions légales</h1>
            <p style={{ color: "#333" }}>Merci de lire avec attention les différentes modalités d’utilisation du présent site avant d’y parcourir ses pages. En vous connectant sur ce site, vous acceptez sans réserves les présentes modalités. Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet www.imogroup-castelnaudary.com sont :</p>

            <h2>Editeur du Site :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: 0 }}>Raison sociale : NEO IMO</li>
                <li style={{ margin: 0 }}>Nom commercial : NEO IMO</li>
                <li style={{ margin: 0 }}>Adresse siège social :48 Rue Saint Jean, 88300 NEUFCHATEAU</li>
                <li style={{ margin: 0 }}>Téléphone : 03.29.94.18.00</li>
                <li style={{ margin: 0 }}>Email : info@neoimo.com</li>
                <li style={{ margin: 0 }}>Site Web : www.neoimo.com</li>
                <li style={{ margin: 0 }}>SIRET : 40968895900041</li>
                <li style={{ margin: 0 }}>RCS : 409 688 959 EPINAL</li>
                <li style={{ margin: 0 }}>Numéro TVA Intracommunautaire : FR65409688959</li>
                <li style={{ margin: 0 }}>Forme juridique : EURL</li>
                <li style={{ margin: 0 }}>Capital social : 20 000 €</li>
                <li style={{ margin: 0 }}>Assurance RCP : COVEA RISKS</li>
                <li style={{ margin: 0 }}>Adresse de l’assureur : 86 Rue Saint-Lazare, 75009 Paris</li>
                <li style={{ margin: 0 }}>Numéro de contrat : 120 137 405</li>
                <li style={{ margin: 0 }}>Carte T : CPI 8801 2016 000 005 818</li>
                <li style={{ margin: 0 }}>Date de délivrance (renouvellement) : 05/04/2022</li>
                <li style={{ margin: 0 }}>Lieu de délivrance : CCI VOSGES</li>
                <li style={{ margin: 0 }}>Caisse de garantie financière : GALIAN</li>
                <li style={{ margin: 0 }}>N° de caisse de garantie : 41388</li>
                <li style={{ margin: 0 }}>Adresse caisse de garantie : 89 rue Boétie 75008 PARIS</li>
                <li style={{ margin: 0 }}>Montant de la garantie financière : 120 000€</li>
                <li style={{ margin: 0 }}>Responsable éditorial : Luca BIBLOT</li>
            </ul>

            <h2>Médiation :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: 0 }}>Nom du médiateur : L’association National des Médiateurs (ANM Conso)</li>
                <li style={{ margin: 0 }}>Adresse du médiateur : 62 rue Tiquetonne 75002 PARIS</li>
                <li style={{ margin: 0 }}>Adresse du site : www.anm-conso.com</li>
            </ul>

            <h2>Hébergement :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: 0 }}>Ce site est hébergé par la société OVH : http://www.ovh.com</li>
                <li style={{ margin: 0 }}>Le nom de domaine est hébergé par la société OVH : http://www.ovh.com</li>
                <li style={{ margin: 0 }}>Siège social : 2 rue Kellermann – 59100 Roubaix – France.</li>
            </ul>

            <h2>Développement :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: 0 }}>SQUARECOM Adresse : 4 Av. Président Kennedy, 88300 Neufchâteau Site web : https://square-com.fr</li>
            </ul>

            <h2>Conditions d’utilisation :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: "15px 0px" }}>Ce site (www.neoimo.com) est proposé en différents langages web  pour un meilleur confort d’utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes comme Internet explorer, Safari, Firefox, Google Chrome, etc….</li>
                <li style={{ margin: "15px 0px" }}>NEOIMO met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L’internaute devra donc s’assurer de l’exactitude des informations auprès de NEOIMO, et signaler toutes modifications du site qu’il jugerait utile. n’est en aucun cas responsable de l’utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.</li>
                <li style={{ margin: "15px 0px" }}>Cookies : Le site www.neoimo.com peut-être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d’affichage. Un cookies est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations . Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.</li>
                <li style={{ margin: "15px 0px" }}>Liens hypertextes : Les sites internet de peuvent offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet. www.neoimo.com ne dispose d’aucun moyen pour contrôler les sites en connexion avec ses sites internet. ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l’internaute, qui doit se conformer à leurs conditions d’utilisation. Les utilisateurs, les abonnés et les visiteurs des sites internet de ne peuvent mettre en place un hyperlien en direction de ce site sans l’autorisation expresse et préalable de NEOIMO. Dans l’hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en direction d’un des sites internet de NEOIMO, il lui appartiendra d’adresser un email accessible sur le site afin de formuler sa demande de mise en place d’un hyperlien. NEOIMO se réserve le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier sa décision.</li>
                <li style={{ margin: "15px 0px" }}>Services fournis : L’ensemble des activités de la société ainsi que ses informations sont présentés sur notre site www.imogroup-aceimmo.com.

                    NEOIMO s’efforce de fournir sur le site www.neoimo.com des informations aussi précises que possible. les renseignements figurant sur le site www.neoimo.com ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, tous les informations indiquées sur le site www.neoimo.com sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.</li>
            </ul>

            <h2>Limitation contractuelles sur les données :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: "15px 0px" }}>Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par email, à l’adresse info@neoimo.com, en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de navigateur utilisé, …). Tout contenu téléchargé se fait aux risques et périls de l’utilisateur et sous sa seule responsabilité. En conséquence, ne saurait être tenu responsable d’un quelconque dommage subi par l’ordinateur de l’utilisateur ou d’une quelconque perte de données consécutives au téléchargement. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour Les liens hypertextes mis en place dans le cadre du présent site internet en direction d’autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de NEOIMO.</li>
            </ul>

            <h2>Propriété intellectuelle :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: "15px 0px" }}>Tout le contenu du présent sur le site, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs. Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l’accord exprès par écrit de NEOIMO. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.</li>
            </ul>

            <h2>Déclaration à la CNIL :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: "15px 0px" }}>Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l’égard des traitements de données à caractère personnel) relative à l’informatique, aux fichiers et aux libertés, ce site n’a pas fait l’objet d’une déclaration auprès de la Commission nationale de l’informatique et des libertés (www.cnil.fr).</li>
            </ul>

            <h2>Litiges :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: "15px 0px" }}>Les présentes conditions du site www.neoimo.com sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le français.</li>
            </ul>

            <h2>Données personnelles :</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ margin: "15px 0px" }}>De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet www.neoimo.com. Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom, votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ». Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de solliciter des renseignements sur notre société, ou de recevoir les lettres d’information. Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors d’une simple navigation sur notre site Internet, notamment : des informations concernant l’utilisation de notre site, comme les zones que vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre navigateur, vos temps d’accès. De telles informations sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</li>
            </ul>
            {/* NEO IMO
                Raison sociale : NEO IMO
                Nom commercial : NEO IMO
                Adresse siège social :48 Rue Saint Jean, 88300 NEUFCHATEAU
                Téléphone : 03.29.94.18.00
                Email : info@neoimo.com
                Site Web : www.neoimo.com
                SIRET : 40968895900041
                RCS :409 688 959 EPINAL
                Numéro TVA Intracommunautaire : FR65409688959
                Forme juridique : EURL
                Capital social : 20 000 €
                Assurance RCP : COVEA RISKS Adresse de l’assureur : 86 Rue Saint-Lazare, 75009 Paris Numéro de contrat : 120 137 405
                Carte T : CPI 8801 2016 000 005 818
                Date de délivrance (renouvellement) : 05/04/2022
                Lieu de délivrance : CCI VOSGES
                Caisse de garantie financière : GALIAN
                N° de caisse de garantie : 41388
                Adresse caisse de garantie : 89 rue Boétie 75008 PARIS
                Montant de la garantie financière : 120 000€
                Responsable éditorial : Luca BIBLOT */}
            {/* Ajouter le reste du contenu ici */}
        </div>
        <Footer />
    </div>
}

export default MentionLegal

